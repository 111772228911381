export const validateEmail = email => {
  const emailRegexp = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
  return emailRegexp.test(email);
};

export const validateEmailList = value => {
  if (!value || !value.trim) return { valid: false, emails: [] };
  const emails = value
    .trim()
    .split(",")
    .map(i => i.trim());
  return { valid: !emails.some(i => !validateEmail(i)), emails };
};

export const validate = (name, value, options = {}) => {
  switch (name) {
    case "email": {
      if (options.required && !value) {
        return "Proszę podać adres email";
      } else if (!validateEmail(value)) {
        return "Podany adres email jest niepoprawny";
      }
      break;
    }
    case "firstName": {
      if (options.required && !value) {
        return "Proszę podać imię";
      } else if (value.length > 20) {
        return "Imię nie może być dłuższe niż 20 znaków";
      }
      break;
    }
    case "lastName": {
      if (options.required && !value) {
        return "Proszę podać nazwisko";
      } else if (value.length > 30) {
        return "Nazwisko nie może być dłuższe niż 30 znaków";
      }
      break;
    }
    case "companyName": {
      if (options.required && !value) {
        return "Proszę podać nazwę firmy";
      } else if (value.length > 30) {
        return "Nazwa firmy nie może być dłuższa niż 30 znaków";
      }
      break;
    }
    case "phone": {
      const phoneRegexp = /\d{9}/g;
      if (options.required && !value) {
        return "Proszę podać nr telefonu";
      } else if (!phoneRegexp.test(value.replace(/\s/g, ""))) {
        return "Numer telefonu powinien zawierać jedynie 9 cyfr";
      }
      break;
    }
    case "emailList": {
      const { valid, emails } = validateEmailList(value);
      if (!valid && value) {
        if (emails.length < 2) {
          return "Podany adres email jest niepoprawny";
        } else {
          return "Co najmniej jeden z podanych adresów email jest niepoprawny";
        }
      }

      break;
    }
    case "name": {
      if (options.required && !value) {
        return "Proszę podać nazwę";
      } else if (value.length > 25) {
        return "Podana nazwa jest za długa. Proszę podać nazwę krótszą niż 25 znaków.";
      }
      break;
    }
    case "city": {
      if (options.required && !value) {
        return "Proszę podać nazwę miasta";
      } else if (value.length > 25) {
        return "Podana nazwa jest za długa. Proszę podać nazwę krótszą niż 25 znaków.";
      }
      break;
    }
    case "password": {
      const extraCharacterRegexp = /.*[0-9!@#$%^&*()_\-+=~`,<.>\/?;:'"\[{}\]].*/;
      if (options.required && !value) {
        return "Proszę podać hasło";
      } else if (value.length < 8) {
        return "Hasło jest za krótkie";
      } else if (!extraCharacterRegexp.test(value)) {
        return "Hasło musi zawierać liczbę lub znak specjalny";
      }
      break;
    }
    case "confirmPassword": {
      if (options.required && !value) {
        return "Proszę potwierdzić hasło";
      } else if (value !== options.valueToCompare) {
        return "Hasła się różnią";
      }
      break;
    }
    case "accessCode": {
      if (options.required && !value) {
        return "Proszę podać kod dostępu";
      } else if (value.length !== 10) {
        return "Kod dostępu musi mieć 10 znaków";
      }
      break;
    }
    default:
      return null;
    // throw new Error(`Validation of unknown field "${name}"!`);
  }
  // return null;
};

import * as AT from "../constants/actionTypes";
import { createPromiseAction } from "../utils/misc";
import * as Reports from "../api/reports";
import {
  updateVisibleElements,
  changeViewLoadingState,
  setViewState,
} from "./uiState";

export const getReports = createPromiseAction(
  Reports.get,
  (result, args, dispatch) => {
    dispatch({ type: AT.GET_REPORTS_SUCCESS, payload: result.data });
    dispatch(updateVisibleElements("reports"));
    dispatch(sortReports());
    dispatch(changeViewLoadingState("reports", false));
  },
  null,
  dispatch => {
    dispatch(changeViewLoadingState("reports", true));
  }
);

export const getReportPdf = createPromiseAction(Reports.downloadPdf);

export const deleteReport = createPromiseAction(
  Reports.deleteOne,
  (result, args, dispatch) => {
    dispatch({ type: AT.DELETE_REPORTS_SUCCESS, payload: args[0] });
    dispatch(updateVisibleElements("reports"));
  }
);

export const calculateVisibleReports = () => (dispatch, getState) => {
  const { employees, templates, reports } = getState().reducer;
  const items = Object.values(reports.data).map(item => {
    let template = templates.data[item.template_id] || { info: { name: "-" } };
    let employee = employees.data[item.author];
    return {
      id: item._id,
      templateName: template.info.name,
      employee,
    };
  });
  const { searchBox } = reports;
  let visible = items
    .filter(
      item =>
        !searchBox ||
        JSON.stringify(item)
          .toLocaleLowerCase()
          .includes(searchBox.toLocaleLowerCase())
    )
    .map(item => item.id);
  dispatch({
    type: AT.UPDATE_VISIBLE_ELEMENTS,
    payload: { visible, view: "reports" },
  });
};

export const sortReports = sortKey => (dispatch, getState) => {
  const { templates, employees, reports } = getState().reducer;
  const { sorting, data, order } = reports;
  let newSorting = { ...sorting };
  if (sortKey) {
    if (newSorting.key === sortKey) {
      newSorting.decr = !newSorting.decr;
    } else {
      newSorting = { key: sortKey, decr: false };
    }
  }

  const { key } = newSorting;
  let newOrder = Object.values(data)
    .sort((a, b) => {
      let multi = newSorting.decr ? -1 : 1;
      switch (newSorting.key) {
        case "templateName": {
          let templateA = templates.data[a.template_id]
            ? templates.data[a.template_id].info.name
            : null;
          let templateB = templates.data[b.template_id]
            ? templates.data[b.template_id].info.name
            : null;
          if (templateA === templateB) return 0;
          return templateA > templateB ? multi : -1 * multi;
        }
        case "locationName": {
          let locationA = employees.data[a.author];
          locationA = locationA
            ? `${locationA.last_name} - ${locationA.first_name}`
            : null;
          let locationB = employees.data[b.author];
          locationB = locationB
            ? `${locationB.last_name} - ${locationB.first_name}`
            : null;
          if (locationA === locationB) return 0;
          return locationA > locationB ? multi : -1 * multi;
        }
        default: {
          return a[key] > b[key] ? multi : -1 * multi;
        }
      }
    })
    .map(i => i._id);
  dispatch({
    type: AT.UPDATE_ELEMENTS_ORDER,
    payload: {
      view: "reports",
      order: newOrder,
      sorting: newSorting,
    },
  });
};

import * as AT from "../../constants/actionTypes";

const initialState = {
  mode: null,
  templateId: null,
  template: null,
  answers: {},
  stepsWithMissingAnswers: {},
  hiddenSteps: {},
  skippedSteps: {},
  sending: false,
  reportId: "",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AT.START_REPORT_CREATION: {
      return {
        ...state,
        mode: "create",
        answers: {},
        templateId: action.payload.templateId,
        template: action.payload.template,
        hiddenSteps: action.payload.hiddenSteps,
      };
    }
    case AT.START_REPORT_EDIT: {
      return {
        ...state,
        mode: "edit",
        answers: action.payload.answers,
        templateId: action.payload.templateId,
        template: action.payload.template,
        hiddenSteps: action.payload.hiddenSteps,
        skippedSteps: action.payload.skippedSteps,
        reportId: action.payload.id,
      };
    }
    case AT.CHANGE_ANSWER_VALUE: {
      return {
        ...state,
        answers: {
          ...state.answers,
          [action.payload.stepId]: action.payload.value,
        },
        hiddenSteps: { ...action.payload.hiddenSteps },
        stepsWithMissingAnswers: {
          ...state.stepsWithMissingAnswers,
          [action.payload.stepId]: undefined,
        },
      };
    }
    case AT.REPORT_VIEW_MISSING_ANSWERS: {
      const stepsWithMissingAnswers = action.payload.reduce(
        (sum, stepId) => ({ ...sum, [stepId]: true }),
        {}
      );
      return { ...state, stepsWithMissingAnswers };
    }
    case AT.REPORT_VIEW_START_SENDING: {
      return { ...state, sending: true };
    }
    case AT.REPORT_VIEW_SENDING_ERROR: {
      return { ...state, sending: false };
    }
    case AT.REPORT_VIEW_SENDING_SUCCESS: {
      return { ...initialState };
    }
    case AT.REPORT_VIEW_SKIP_STEP_TOGGLE: {
      return {
        ...state,
        skippedSteps: {
          ...state.skippedSteps,
          [action.payload]: !state.skippedSteps[action.payload],
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;

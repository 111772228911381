import React from "react";
import PropTypes from "prop-types";
import { Input } from "antd";

import "./searchBox.scss";

const { Search } = Input;

const SearchBox = props => {
  return (
    <Search
      placeholder="Wyszukaj"
      enterButton
      allowClear
      style={{ width: "200px" }}
      {...props}
    />
  );
};

SearchBox.defaultProps = {};

SearchBox.propTypes = {};

export default SearchBox;

import React, { Fragment } from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { processModifiers } from "../../../utils/misc";
import HeaderItem from '../headerItem/HeaderItem.redux';

import "./dataTable.scss";

const DataTableHeader = props => {
  const { items, colgroups, modifiers, viewName } = props;
  const disabled = props.disabled || modifiers.includes("disabled");
  const baseClassName = classNames("c-dataTable", props.className, {
    "c-button--disabled": disabled
  });
  const className = processModifiers(baseClassName, "c-dataTable", modifiers);
  return (
    <Fragment>
      <thead>
        <tr>
          {items.map((item, i) => (
              <HeaderItem title={item.title} key={`header-${i}`} sortKey={item.sortKey} viewName={viewName}/>

          ))}
        </tr>
      </thead>
      {colgroups ? (
        <colgroup>
          {colgroups.map((c, i) => (
            <col key={`colgroup-${i}`} {...c} />
          ))}
        </colgroup>
      ) : null}
    </Fragment>
  );
};

DataTableHeader.defaultProps = {
    modifiers: []
};

DataTableHeader.propTypes = {};

export default DataTableHeader;

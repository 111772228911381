import moment from "moment";
import * as AT from "../../constants/actionTypes";
import { getFieldDefaultValue } from "../../constants/fieldTypesDefaultValues";

const initialState = {
  templateId: null,
  currentStep: null,
  steps: {},
  currentStepData: {},
  currentStepLoadingPhotos: [],
  submitted: false,
  sendingProgress: {
    sending: false,
    progress: 0,
    error: false,
    message: "",
    errorInfo: "",
  },
  info: {},
};

const reducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case AT.CHANGE_INPUT_VALUE: {
      if (action.payload.view === "newReport") {
        const { name, value } = action.payload;
        return {
          ...state,
          currentStepData: { ...state.currentStepData, [name]: value },
        };
      }

      return state;
    }
    case AT.REMOVE_PHOTO: {
      if (action.payload.view === "newReport") {
        const { index } = action.payload;
        return {
          ...state,
          currentStepData: {
            ...state.currentStepData,
            value: [
              ...state.currentStepData.value.slice(0, index),
              ...state.currentStepData.value.slice(index + 1),
            ],
          },
        };
      }
      return state;
    }
    case AT.ADD_PHOTO_LOADER: {
      if (action.payload.view === "newReport") {
        const { uid } = action.payload;
        return {
          ...state,
          currentStepData: {
            ...state.currentStepData,
            value: [...state.currentStepData.value, { loading: true, uid }],
          },
          currentStepLoadingPhotos: state.currentStepLoadingPhotos
            ? [...state.currentStepLoadingPhotos, uid]
            : [uid],
        };
      }
      return state;
    }
    case AT.REMOVE_PHOTO_LOADER: {
      if (action.payload.view === "newReport") {
        const { uid } = action.payload;
        return {
          ...state,
          currentStepData: {
            ...state.currentStepData,
            value: state.currentStepData.value.filter(photo => {
              return photo.uid !== uid;
            }),
          },
          currentStepLoadingPhotos: state.currentStepLoadingPhotos.filter(
            photo => !photo.uid === uid
          ),
        };
      }
      return state;
    }
    case AT.ADD_PHOTO: {
      if (action.payload.view === "newReport") {
        const { name, value, uid } = action.payload;
        if (
          state.currentStepLoadingPhotos &&
          state.currentStepLoadingPhotos.includes(uid)
        ) {
          return {
            ...state,
            currentStepData: {
              ...state.currentStepData,
              value: state.currentStepData.value.map(photo => {
                if (photo.uid === uid) {
                  return value;
                }
                return photo;
              }),
            },
            currentStepLoadingPhotos: state.currentStepLoadingPhotos.filter(
              photo => !photo.uid === uid
            ),
          };
        }
        return {
          ...state,
          currentStepData: {
            ...state.currentStepData,
            [name]: [...state.currentStepData[name], value],
          },
        };
      }
      return state;
    }
    case AT.RESET_VIEW_STATE: {
      if (action.payload === "newReport") {
        return { ...initialState };
      }
      return state;
    }
    case AT.SET_VIEW_STATE: {
      const { viewName, data } = action.payload;
      if (viewName === "newReport") {
        return { ...state, ...data };
      }
      return state;
    }
    case AT.START_REPORT_CREATION: {
      return {
        ...state,
        templateId: action.payload.templateId,
        currentStep: 1,
        currentStepData: getFieldDefaultValue(action.payload.firstStepType),
        info: { ...state.info, started_at: moment().toISOString() },
      };
    }
    case AT.NEW_REPORT_GO_TO_STEP: {
      const goToStepData =
        state.steps[action.payload.stepNumber] ||
        getFieldDefaultValue(action.payload.stepType);
      console.log(
        "GOT data",
        goToStepData,
        getFieldDefaultValue(action.payload.stepType)
      );
      return {
        ...state,
        currentStep: action.payload.stepNumber,
        steps: {
          ...state.steps,
          [state.currentStep]: {
            ...state.currentStepData,
            skipped: action.payload.skipCurrent,
            submited_at: moment().toISOString(),
          },
        },
        currentStepData: goToStepData,
      };
    }
    case AT.SUBMIT_NEW_REPORT: {
      return {
        ...state,
        currentStep: 1,
        steps: {
          ...state.steps,
          [state.currentStep]: {
            ...state.currentStepData,
            submited_at: moment().toISOString(),
          },
        },
        currentStepData: {},
        submitted: true,
        info: { ...state.info, finished_at: moment().toISOString() },
      };
    }
    case AT.UPDATE_SENDING_STATUS: {
      return {
        ...state,
        submitted: action.payload.progress === 100 ? false : state.submitted,
        sendingProgress: {
          ...state.sendingProgress,
          ...action.payload,
        },
      };
    }
    default:
      return state;
  }
};

export default reducer;

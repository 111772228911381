import React, { Component } from "react";
import { SearchBox } from "../common/";
import { Col, PageHeader, Row, Button, Progress } from "antd";
import {
  SnippetsTwoTone,
  LeftOutlined,
  RightOutlined,
} from "@ant-design/icons";
import PicturesWall from "./PIctureGrid";
import Rating from "./Rating";
import TextStep from "./TextStep";
import NumberStep from "./NumberStep";
import BooleanStep from "./BooleanStep";
import { startNewReportCreation } from "../../actions/newReport";

class NewReportView extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.add = this.add.bind(this);
    this.openImportModal = this.openImportModal.bind(this);
  }

  handleInputChange(path, event) {
    this.props.changeInputValue("newTemplate", event, path);
  }

  add() {
    this.props.history.push("/templates/current");
  }

  openImportModal() {
    this.props.showModal("IMPORT_TEMPLATE");
  }
  goBack() {
    this.props.newReportGoToStep(this.props.uiState.currentStep - 1, {
      skipCurrent: false,
    });
  }
  continue(skip) {
    const { uiState, template } = this.props;
    const { currentStep } = uiState;
    if (template.stepsOrder.length > currentStep) {
      this.props.newReportGoToStep(currentStep + 1, {
        skipCurrent: !!skip,
      });
    } else {
      this.props.submitNewReport();
      this.props
        .sendReport()
        .then(() => {
          // this.exit(1000);
        })
        .catch(err => {
          // this.exit(1000);
        });
    }
  }

  async edit(id) {
    await this.props.startTemplateEdit(id);
    this.props.history.push("/templates/current");
  }
  renderStepInput(step) {
    const { uiState, changeInputValue, template } = this.props;
    const { currentStepData, templateId, currentStep } = uiState;
    switch (step.type.value) {
      case "text":
        return (
          <TextStep
            value={currentStepData.value}
            onChangeText={value => {
              changeInputValue("newReport", {
                target: { value: value.target.value, id: "value" },
              });
            }}
            autofocus={true}
          />
        );
      case "number":
        return (
          <NumberStep
            value={currentStepData.value}
            onChangeText={value => {
              changeInputValue("newReport", { target: { value, id: "value" } });
            }}
            autofocus={true}
          />
        );
      case "rating":
        return (
          <Rating
            value={currentStepData.value}
            onChange={value => {
              console.log("Change", value);
              changeInputValue("newReport", { target: { value, id: "value" } });
            }}
          />
        );
      case "bool":
        return (
          <BooleanStep
            value={currentStepData.value}
            onChange={value => {
              console.log("Bool value", value);
              changeInputValue("newReport", { target: { value, id: "value" } });
            }}
          />
        );
      case "photo":
        return (
          <PicturesWall
            value={currentStepData.value}
            key={currentStep}
            onChange={ids => {
              changeInputValue("newReport", {
                target: {
                  value: ids.map(data => ({ ...data, orientation: "H" })),
                  id: "value",
                },
              });
            }}
          />
        );

      default:
        return <div />;
    }
  }

  render() {
    const { uiState, template } = this.props;
    const { currentStep, submitted, currentStepData } = uiState;
    if (currentStep === null) {
      if (template) {
        this.props.startNewReportCreation(this.props.match.params.id);
      }
      return null;
    }
    if (submitted) {
      return (
        <div>
          <Progress type={"circle"} />
        </div>
      );
    }

    if (!template) return null;
    const step = template.steps[template.stepsOrder[currentStep - 1]];
    let disableContinue = Array.isArray(currentStepData.value)
      ? !currentStepData.value.length
      : !currentStepData.value &&
        currentStepData.value !== 0 &&
        currentStepData.value !== false &&
        step.type.value !== "sectionName";
    const disableBack = currentStep === 1;

    return (
      <React.Fragment>
        <Row justify={"center"}>
          <Col>
            <PageHeader
              title={
                <span>
                  <span>{step.name}</span>
                </span>
              }
            />
          </Col>
        </Row>
        {step.comment ? (
          <Row justify={"center"}>
            <Col>
              <PageHeader
                title={
                  <span>
                    <span style={{ fontSize: "14px" }}>{step.comment}</span>
                  </span>
                }
              />
            </Col>
          </Row>
        ) : null}
        <Row justify={"center"} gutter={[7, 14]}>
          <Col span={12}>{this.renderStepInput(step)}</Col>
        </Row>
        {/*        <Row style={{ padding: "0 20px" }}>*/}
        {/*          <Col span={24}>*/}
        {/*<PicturesWall/>*/}
        {/*          </Col>*/}
        {/*        </Row>*/}
        {/*          <Row style={{ padding: "0 20px" }}>*/}
        {/*              <Col span={24}>*/}
        {/*                  <Rating/>*/}
        {/*              </Col>*/}
        {/*          </Row>*/}

        {/*      <Row style={{ padding: "0 20px" }}>*/}
        {/*          <Col span={24}>*/}
        {/*              <TextStep/>*/}
        {/*          </Col>*/}
        {/*      </Row>*/}

        {/*          <Row style={{ padding: "0 20px" }}>*/}
        {/*              <Col span={24}>*/}
        {/*                  <NumberStep/>*/}
        {/*              </Col>*/}
        {/*          </Row>*/}
        <Row style={{ padding: "0 20px" }} gutter={7} justify={"center"}>
          <Col>
            {" "}
            <Button
              type="primary"
              disabled={disableBack}
              onClick={() => this.goBack()}
              icon={<LeftOutlined />}
            ></Button>
          </Col>
          {
            <Col>
              {" "}
              <Button
                type="secondary"
                disabled={step.required}
                onClick={() => this.continue(true)}
              >
                Pomiń
              </Button>
            </Col>
          }
          <Col>
            <Button
              type="primary"
              disabled={disableContinue}
              onClick={() => this.continue(false)}
            >
              Zatwierdź
            </Button>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default NewReportView;

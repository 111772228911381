export const getFieldDefaultValue = type => {
  const fieldTypesDefaultValues = {
    rating: { value: 0 },
    text: { value: "" },
    number: { value: 0 },
    photo: { value: [] },
    bool: { value: true },
    sectionName: { value: null },
  };
  return { ...fieldTypesDefaultValues[type] };
};

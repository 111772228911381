import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { processModifiers } from "../../../utils/misc";
import Icon from "@mdi/react";
import * as Icons from "@mdi/js";

import "./link.scss";

const Link = props => {
  const { modifiers, icon, title, onClick } = props;
  const disabled = props.disabled || modifiers.includes("disabled");
  const baseClassName = classNames("c-link", props.className, {
    "c-link--disabled": disabled
  });
  const className = processModifiers(baseClassName, "c-link", modifiers);
  return (
    <span className={className} onClick={onClick}>
      <Icon className="c-link__icon" path={Icons[icon]} size="18px" />
      <span className="c-link__title">{title}</span>
    </span>
  );
};

Link.defaultProps = {
  modifiers: []
};

Link.propTypes = {};

export default Link;

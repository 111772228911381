import React, { Component } from "react";
import { Route } from "react-router-dom";
import { Button, TextInput } from "../common/";
import Drawer from "../drawer/Drawer.redux";
import "./authApp.scss";

import OfferList from "../offerList/OfferList.jsx";
import TemplatesView from "../templatesView/TemplatesView.redux";
import ReportsView from "../reportsView/ReportsView.redux";
import EmployeesView from "../employeesView/EmployeesView.redux";
import CurrentTemplate from "../currentTemplateView/CurrentTemplate.redux";
import FillReportView from "../fillReportView/FillReportView.redux";
import NewReportView from "../newReport/NewReportView.redux";
import CreateReportView from "../createReportView/CreateReportView.redux";
import EditReportView from "../editReportView/EditReportView.redux";

class AuthApp extends Component {
  constructor(props) {
    super(props);

    this.routes = [
      {
        path: "/templates/",
        exact: true,
        component: TemplatesView,
        permission: "TEMPLATES_VIEW:USE",
      },
      {
        path: "/employees",
        exact: true,
        component: EmployeesView,
        permission: "LOCATIONS_VIEW:USE",
      },
      {
        path: "/templates/current",
        exact: false,
        component: CurrentTemplate,
        permission: "TEMPLATES_VIEW:USE",
      },
      {
        path: "/reports/",
        exact: true,
        component: ReportsView,
        permission: "REPORTS_VIEW:USE",
      },
      {
        path: "/fillReport",
        exact: true,
        component: FillReportView,
        permission: "FILL_REPORT_VIEW:USE",
      },
      {
        path: "/fillReport/:id",
        exact: false,
        component: NewReportView,
        permission: "FILL_REPORT_VIEW:USE",
      },
      {
        path: "/createReport",
        exact: false,
        component: CreateReportView,
        permission: "FILL_REPORT_VIEW:USE",
      },
      {
        path: "/editReport",
        exact: false,
        component: EditReportView,
        permission: "REPORTS_VIEW:USE",
      },
    ];
  }

  render() {
    const { userPermissions } = this.props;
    const routes = this.routes.filter(route =>
      userPermissions.includes(route.permission)
    );
    return (
      <div className="c-authApp">
        <Drawer />
        <div className="c-content">
          {routes.map(route => (
            <Route {...route} />
          ))}
        </div>
      </div>
    );
  }
}

export default AuthApp;

import { connect } from "react-redux";
import Component from "./ConfirmModal.jsx";
import { hideModal } from "../../../actions/uiState";

const mapStateToProps = state => ({
  uiState: state.reducer.uiState.modal.data,
  show: state.reducer.uiState.modal.currentModal === "CONFIRM_MODAL",
});

const mapDispatchToProps = {
  hideModal,
};

const AttachEmployeeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default AttachEmployeeContainer;

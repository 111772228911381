import React, { Component } from "react";
import { Button, Table, ConfigProvider, Popconfirm, Empty } from "antd";
import { DeleteTwoTone, EditTwoTone, WarningTwoTone } from "@ant-design/icons";

class EmployeesTable extends Component {
  constructor(props) {
    super(props);
  }

  edit(employee) {
    const { first_name, last_name, email, _id, notification_email } = employee;
    this.props.setViewState("currentEmployee", {
      firstName: first_name,
      lastName: last_name,
      email,
      notificationEmail: notification_email,
      id: _id,
    });
    this.props.showModal("CURRENT_EMPLOYEE");
  }

  render() {
    const { employees, deleteEmployee } = this.props;
    const { order, visible, data, loading } = employees;

    const processedEmployees = order
      .filter(id => visible.includes(id))
      .map(id => data[id]);

    return (
      <ConfigProvider
        renderEmpty={() => {
          if (loading) return <div className="loadingEmptySpace" />;
          return (
            <Empty description={<p>Brak lokalizacji do wyświetlenia</p>} />
          );
        }}
      >
        <Table
          bordered
          loading={loading}
          size={"small"}
          pagination={{
            pageSize: 20,
            hideOnSinglePage: true,
            pageSizeOptions: ["5", "20", "50", "100", "200"],
            showSizeChanger: true,
            locale: { items_per_page: "" },
            showTotal: (total, range) => {
              return `${range[0]} - ${range[1]} z ${total} lokalizacji`;
            },
          }}
          dataSource={processedEmployees}
          columns={[
            {
              title: "Nazwa lokalizacji",
              dataIndex: "first_name",
              align: "center",
              sorter: (a, b) => a.first_name.localeCompare(b.first_name),
            },
            {
              title: "Miasto",
              dataIndex: "last_name",
              align: "center",
              sorter: (a, b) => a.last_name.localeCompare(b.last_name),
            },
            {
              title: "E-mail",
              dataIndex: "email",
              align: "center",
              sorter: (a, b) => a.email.localeCompare(b.email),
            },
            {
              title: "Opcje",
              align: "center",

              render: employee => (
                <React.Fragment>
                  <Button
                    icon={<EditTwoTone />}
                    type={"link"}
                    onClick={() => {
                      this.edit(employee);
                    }}
                  >
                    Edytuj
                  </Button>
                  <Popconfirm
                    title={
                      <span>
                        Czy na pewno chcesz usunąć tą lokalizację?
                        <br /> Operacja jest nieodwracalna!
                      </span>
                    }
                    onConfirm={() => {
                      deleteEmployee(employee._id);
                    }}
                    onCancel={() => {}}
                    okText="Usuń"
                    cancelText="Anuluj"
                    icon={<WarningTwoTone />}
                  >
                    <Button icon={<DeleteTwoTone />} type={"link"}>
                      Usuń
                    </Button>
                  </Popconfirm>
                </React.Fragment>
              ),
            },
          ]}
        />
      </ConfigProvider>
    );
  }
}

export default EmployeesTable;

import React, { Component } from "react";
import { SearchBox } from "../common/";
import CurrentEmployeeModal from "../employeesView/employeeModal/EmployeeModal.redux";
import { Col, PageHeader, Row, Button } from "antd";
import { ReconciliationOutlined, FileAddOutlined } from "@ant-design/icons";
import TemplatesTable from "./templatesTable/TemplatesTable.redux";

class TemplatesView extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.add = this.add.bind(this);
    this.openImportModal = this.openImportModal.bind(this);
  }

  handleInputChange(path, event) {
    this.props.changeInputValue("newTemplate", event, path);
  }

  add() {
    this.props.history.push("/templates/current");
  }

  openImportModal() {
    this.props.showModal("IMPORT_TEMPLATE");
  }

  async edit(id) {
    await this.props.startTemplateEdit(id);
    this.props.history.push("/templates/current");
  }
  render() {
    return (
      <React.Fragment>
        <CurrentEmployeeModal />
        <Row>
          <Col span={24}>
            <PageHeader
              title={
                <span>
                  <ReconciliationOutlined style={{ marginRight: "10px" }} />
                  <span>Szablony</span>
                </span>
              }
              extra={[
                <Button
                  type="primary"
                  icon={<FileAddOutlined />}
                  onClick={this.add}
                >
                  Nowy szablon
                </Button>,
                <SearchBox viewName="templates" />,
              ]}
            />
          </Col>
        </Row>
        <Row style={{ padding: "0 20px" }}>
          <Col span={24}>
            <TemplatesTable history={this.props.history} />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default TemplatesView;

import { connect } from "react-redux";
import Component from "./LoginView.jsx";
import { changeInputValue } from "../../actions/uiState";
import { login } from "../../actions/auth";

const mapStateToProps = state => ({
  uiState: state.reducer.uiState.login,
});

const mapDispatchToProps = {
  changeInputValue,
  login,
};

const Container = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Container;

import axios from "axios";

import store from "../store";

class ConnectionManager {
  constructor(connections) {
    this.base_url = connections.core;

    this.authToken = "";
    this.conn = axios.create();
  }

  setAuthToken(token) {
    this.authToken = token;
    return Promise.resolve();
  }

  getJSON(url) {
    return this.conn.get(url, {
      headers: {
        "x-access-token": this.authToken,
      },
    });
  }

  postJSON(url, payload) {
    return this.conn.post(url, payload, {
      headers: {
        "x-access-token": this.authToken,
      },
    });
  }

  putJSON(url, payload) {
    return this.conn.put(url, payload, {
      headers: {
        "x-access-token": this.authToken,
      },
    });
  }

  deleteJSON(url, payload) {
    return this.conn.delete(url, {
      data: payload,
      headers: {
        "x-access-token": this.authToken,
      },
    });
  }
  getFile(url, fileName) {
    return new Promise((resolve, reject) => {
      this.conn
        .get(url, {
          headers: {
            "x-access-token": this.authToken,
          },
          responseType: "blob",
        })
        .then(response => {
          const url = window.URL.createObjectURL(new Blob([response.data]));
          const link = document.createElement("a");
          link.href = url;
          link.setAttribute("download", fileName);
          document.body.appendChild(link);
          link.click();
          resolve(response.data);
        });
    });
  }
}

export default ConnectionManager;

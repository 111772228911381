import * as AT from "../../constants/actionTypes";

const initialState = {
  currentModal: null,
  data: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AT.SHOW_MODAL: {
      const { modalName, data } = action.payload;
      return { ...state, currentModal: modalName, data };
    }
    case AT.HIDE_MODAL: {
      return { ...state, currentModal: null, data: {} };
    }
    default:
      return state;
  }
};

export default reducer;

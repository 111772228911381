import moment from "moment";
import * as AT from "../constants/actionTypes";

const initialState = {
  start: moment()
    .subtract(7, "days")
    .format("YYYY.MM.DD"),
  end: moment().format("YYYY.MM.DD"),
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AT.CHANGE_DATE_RANGE: {
      return { ...state, start: action.payload.start, end: action.payload.end };
    }
    case AT.AUTH_USER_LOGOUT: {
      return { ...initialState };
    }
    default:
      return state;
  }
};

export default reducer;

import { connect } from "react-redux";
import Component from "./EmployeeModal.jsx";
import {
  hideModal,
  changeInputValue,
  resetView,
} from "../../../actions/uiState";
import { saveCurrentEmployee } from "../../../actions/employees";

const mapStateToProps = state => ({
  uiState: state.reducer.uiState.currentEmployee,
});

const mapDispatchToProps = {
  changeInputValue,
  hideModal,
  saveCurrentEmployee,
  resetView,
};

const AttachEmployeeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default AttachEmployeeContainer;

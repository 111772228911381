import React, { Component } from "react";
import { Switch } from "antd";

export default class Rating extends Component {
  render() {
    return (
      <>
        <Switch
          checkedChildren="Tak"
          unCheckedChildren="Nie"
          defaultChecked
          checked={this.props.value}
          onChange={this.props.onChange}
        />
      </>
    );
  }
}

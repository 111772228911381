import React, { Component } from "react";
import { Input } from "antd";

const { TextArea } = Input;

const TextStep = ({ onChangeText, value, onBlur }) => {
  return (
    <TextArea
      placeholder="..."
      autoSize={{ minRows: 2, maxRows: 20 }}
      onChange={onChangeText}
      value={value}
      onBlur={onBlur}
    />
  );
};

export default TextStep;

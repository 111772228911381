import React from "react";
import ReportView from "../reportView/ReportView.jsx";

const EditReportView = props => {
  return (
    <ReportView
      {...props}
      returnPath={"/reports"}
      onSubmit={props.saveReportEdit}
    />
  );
};

export default EditReportView;

import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import Component from "./Drawer.jsx";
import {logOut} from "../../actions/auth";

const mapStateToProps = state => ({
    userPermissions: state.reducer.auth.role?state.reducer.auth.role.permissions:[],
});

const mapDispatchToProps = {
    logOut
};

const AttachEmployeeContainer = withRouter(connect(
  mapStateToProps,
  mapDispatchToProps
)(Component));

export default AttachEmployeeContainer;

import { connect } from "react-redux";
import Component from "./ReportViewQuestion.jsx";
import { onAnswerChange, toggleStep } from "../../../actions/ReportView";

const mapStateToProps = (state, ownProps) => ({
  step: state.reducer.uiState.reportView.template.steps[ownProps.stepId],
  skipped: state.reducer.uiState.reportView.skippedSteps[ownProps.stepId],
  value: state.reducer.uiState.reportView.answers[ownProps.stepId],
  isMissingAnswer:
    state.reducer.uiState.reportView.stepsWithMissingAnswers[ownProps.stepId],
});

const mapDispatchToProps = { onAnswerChange, skipStep: toggleStep };

const Container = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Container;

import React, { Component } from "react";
import { SearchBox } from "../common/";
import { Col, PageHeader, Row, Button } from "antd";
import { SnippetsTwoTone, FileAddOutlined } from "@ant-design/icons";
import TemplatesTable from "./availableTemplatesTable/AvailableTemplatesTable.redux";

class FillReportView extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.add = this.add.bind(this);
    this.openImportModal = this.openImportModal.bind(this);
  }

  handleInputChange(path, event) {
    this.props.changeInputValue("newTemplate", event, path);
  }

  add() {
    this.props.history.push("/templates/current");
  }

  openImportModal() {
    this.props.showModal("IMPORT_TEMPLATE");
  }

  async edit(id) {
    await this.props.startTemplateEdit(id);
    this.props.history.push("/templates/current");
  }
  render() {
    return (
      <React.Fragment>
        <Row>
          <Col span={24}>
            <PageHeader
              title={
                <span>
                  <SnippetsTwoTone twoToneColor={'#666'} style={{ marginRight: "10px" }}/>
                  <span>Dostępne raporty</span>
                </span>
              }
              extra={[
                <SearchBox viewName="fillReport" />,
              ]}
            />
          </Col>
        </Row>
        <Row style={{ padding: "0 20px" }}>
          <Col span={24}>
            <TemplatesTable history={this.props.history} />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default FillReportView;

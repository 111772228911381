import * as AT from "../constants/actionTypes";
import * as Employees from "../api/employees";
import { createPromiseAction } from "../utils/misc";
import {
  updateVisibleElements,
  changeViewLoadingState,
  setViewState,
} from "./uiState";
import { validate } from "../utils/validation";

export const employeeValidationFields = employee => ({
  email: { name: "email", key: "email", options: { required: true } },
  firstName: { name: "name", key: "firstName", options: { required: true } },
  lastName: { name: "city", key: "lastName", options: { required: true } },
  notificationEmail: {
    name: "emailList",
    key: "notificationEmail",
    options: {},
  },
  password: {
    name: "password",
    key: "password",
    options: { required: !employee.id },
  },
});

export const registerValidationFields = allFields => ({
  firstName: {
    name: "firstName",
    key: "firstName",
    options: { required: true },
  },
  lastName: { name: "lastName", key: "lastName", options: { required: true } },
  companyName: {
    name: "companyName",
    key: "companyName",
    options: { required: true },
  },
  phone: { name: "phone", key: "phone", options: { required: true } },
  email: { name: "email", key: "email", options: { required: true } },
  password: { name: "password", key: "password", options: { required: true } },
  confirmPassword: {
    name: "confirmPassword",
    key: "confirmPassword",
    options: { required: true, valueToCompare: allFields.password },
  },
  accessCode: {
    name: "accessCode",
    key: "accessCode",
    options: { required: true },
  },
});

export const getEmployees = createPromiseAction(
  Employees.get,
  (result, args, dispatch) => {
    dispatch({ type: AT.GET_EMPLOYEES_SUCCESS, payload: result.data });
    dispatch(updateVisibleElements("employees"));
    dispatch(changeViewLoadingState("employees", false));
  },
  null,
  dispatch => {
    dispatch(changeViewLoadingState("employees", true));
  }
);

export const validateEmployee = employee => dispatch => {
  const fields = Object.values(employeeValidationFields(employee));
  const errors = {};
  fields.forEach(field => {
    const error = validate(field.name, employee[field.key], field.options);
    if (error) errors[field.key] = error;
  });
  dispatch(setViewState("currentEmployee", { errors }));
  return !Object.values(errors).length;
};

export const validateProp = (key, value, employee) => {
  const field = employeeValidationFields(employee)[key];
  return validate(field.name, value, field.options);
};

export const saveCurrentEmployee = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const { uiState } = getState().reducer;
    const { currentEmployee } = uiState;

    const {
      firstName,
      lastName,
      email,
      password,
      notificationEmail,
    } = currentEmployee;
    const valid = dispatch(validateEmployee(currentEmployee));
    if (!valid) return false;
    const employee = {
      _id: currentEmployee.id,
      first_name: firstName,
      last_name: lastName,
      email,
      password,
      notification_email: notificationEmail,
    };
    if (employee._id) {
      dispatch(updateEmployee(employee))
        .then(resolve)
        .catch(reject);
    } else {
      dispatch(addNewEmployee(employee))
        .then(resolve)
        .catch(reject);
    }
  });
};

export const addNewEmployee = createPromiseAction(
  Employees.add,
  (result, args, dispatch) => {
    dispatch({ type: AT.ADD_EMPLOYEE_SUCCESS, payload: result.data });
    dispatch(updateVisibleElements("employees"));
  },
  (result, args, dispatch, getState) => {
    const { currentEmployee } = getState().reducer.uiState;
    const { errors } = currentEmployee;
    if (result.response && result.response.data.code === "E100") {
      dispatch(
        setViewState("currentEmployee", {
          errors: {
            ...errors,
            email: "Podany adres email jest używany przez innego użytkownika",
          },
        })
      );
    } else {
      setViewState("currentEmployee", {
        errors: {
          ...errors,
          password:
            "Wystąpił nieoczekiwany błąd. Skontaktuj się z działem pomocy",
        },
      });
    }
  }
);

export const updateEmployee = createPromiseAction(
  Employees.update,
  (result, args, dispatch) => {
    dispatch({ type: AT.UPDATE_EMPLOYEE_SUCCESS, payload: result.data });
    dispatch(updateVisibleElements("employees"));
  }
);

export const deleteEmployee = createPromiseAction(
  Employees.deleteOne,
  (result, args, dispatch) => {
    dispatch({ type: AT.DELETE_EMPLOYEE_SUCCESS, payload: args[0] });
    dispatch(updateVisibleElements("employees"));
  }
);

export const calculateVisibleEmployees = () => (dispatch, getState) => {
  const { employees } = getState().reducer;
  const items = Object.values(employees.data).map(item => {
    const { first_name: firstName, last_name: lastName, email } = item;
    return {
      id: item._id,
      firstName,
      lastName,
      email,
    };
  });
  const { searchBox } = employees;
  let visible = items
    .filter(
      item =>
        !searchBox ||
        JSON.stringify(item)
          .toLocaleLowerCase()
          .includes(searchBox.toLocaleLowerCase())
    )
    .map(item => item.id);
  dispatch({
    type: AT.UPDATE_VISIBLE_ELEMENTS,
    payload: { visible, view: "employees" },
  });
};

export const AUTH_USER_SUCCESS = "AUTH_USER_SUCCESS";
export const AUTH_USER_ERROR = "AUTH_USER_ERROR";
export const AUTH_USER_LOGOUT = "AUTH_USER_LOGOUT";

export const CHANGE_INPUT_VALUE = "CHANGE_INPUT_VALUE";
export const RESET_VIEW_STATE = "RESET_VIEW_STATE";
export const SET_VIEW_STATE = "SET_VIEW_STATE";
export const SHOW_MODAL = "SHOW_MODAL";
export const HIDE_MODAL = "HIDE_MODAL";
export const CHANGE_SEARCH_VALUE = "CHANGE_SEARCH_VALUE";
export const UPDATE_FILTERS = "UPDATE_FILTERS";
export const UPDATE_VISIBLE_ELEMENTS = "UPDATE_VISIBLE_ELEMENTS";
export const UPDATE_ELEMENTS_ORDER = "UPDATE_ELEMENTS_ORDER";
export const SET_CURRENT_ROUTE = "SET_CURRENT_ROUTE";
export const CHANGE_VIEW_LOADING_STATUS = "CHANGE_VIEW_LOADING_STATUS";

// templates
export const ADD_NEXT_TEMPLATE_ITEM = "ADD_NEXT_TEMPLATE_ITEM";
export const DELETE_TEMPLATE_ITEM = "DELETE_TEMPLATE_ITEM";
export const REORDER_TEMPLATE_ITEM = "REORDER_TEMPLATE_ITEM";
export const SAVE_CURRENT_TEMPLATE = "SAVE_CURRENT_TEMPLATE";
export const GET_TEMPLATES_SUCCESS = "GET_TEMPLATES_SUCCESS";
export const ADD_TEMPLATE_SUCCESS = "ADD_TEMPLATE_SUCCESS";
export const UPDATE_TEMPLATE_SUCCESS = "UPDATE_TEMPLATE_SUCCESS";
export const DELETE_TEMPLATE_SUCCESS = "DELETE_TEMPLATE_SUCCESS";
export const START_TEMPLATE_EDIT = "START_TEMPLATE_EDIT";
export const MOVE_TEMPLATE_STEP = "MOVE_TEMPLATE_STEP";
export const GET_AVA_TEMPLATES_SUCCESS = "GET_AVA_TEMPLATES_SUCCESS";

export const START_RULES_EDIT = "START_RULES_EDIT";
export const CHANGE_RULE_VALUE = "CHANGE_RULE_VALUE";
export const INIT_NEW_RULE = "INIT_NEW_RULE";
export const DELETE_RULE = "DELETE_RULE";
export const SAVE_RULES_FOR_STEP = "SAVE_RULES_FOR_STEP";

export const START_SCORE_EDIT = "START_SCORE_EDIT";
export const CHANGE_SCORE_RULE_VALUE = "CHANGE_SCORE_RULE_VALUE";
export const INIT_NEW_SCORE_RULE = "INIT_NEW_SCORE_RULE";
export const DELETE_SCORE_RULE = "DELETE_SCORE_RULE";
export const SAVE_SCORE_RULES_FOR_STEP = "SAVE_SCORE_RULES_FOR_STEP";

// employees
export const GET_EMPLOYEES_SUCCESS = "GET_EMPLOYEES_SUCCESS";
export const ADD_EMPLOYEE_SUCCESS = "ADD_EMPLOYEE_SUCCESS";
export const UPDATE_EMPLOYEE_SUCCESS = "UPDATE_EMPLOYEE_SUCCESS";
export const DELETE_EMPLOYEE_SUCCESS = "DELETE_EMPLOYEE_SUCCESS";

// reports
export const GET_REPORTS_SUCCESS = "GET_REPORTS_SUCCESS";
export const DELETE_REPORTS_SUCCESS = "DELETE_REPORTS_SUCCESS";

// dates
export const CHANGE_DATE_RANGE = "CHANGE_DATE_RANGE";

// NEW REPORT
export const REMOVE_PHOTO = "REMOVE_PHOTO";
export const ADD_PHOTO_LOADER = "ADD_PHOTO_LOADER";
export const REMOVE_PHOTO_LOADER = "REMOVE_PHOTO_LOADER";
export const ADD_PHOTO = "ADD_PHOTO";
export const START_REPORT_CREATION = "START_REPORT_CREATION";
export const NEW_REPORT_GO_TO_STEP = "NEW_REPORT_GO_TO_STEP";
export const SUBMIT_NEW_REPORT = "SUBMIT_NEW_REPORT";
export const UPDATE_SENDING_STATUS = "UPDATE_SENDING_STATUS";

// Report view
export const CHANGE_ANSWER_VALUE = "CHANGE_ANSWER_VALUE";
export const REPORT_VIEW_MISSING_ANSWERS = "REPORT_VIEW_MISSING_ANSWERS";
export const REPORT_VIEW_START_SENDING = "REPORT_VIEW_START_SENDING";
export const REPORT_VIEW_SENDING_SUCCESS = "REPORT_VIEW_SENDING_SUCCESS";
export const REPORT_VIEW_SENDING_ERROR = "REPORT_VIEW_SENDING_ERROR";
export const REPORT_VIEW_SKIP_STEP_TOGGLE = "REPORT_VIEW_SKIP_STEP_TOGGLE";

// Edit report view
export const START_REPORT_EDIT = "START_REPORT_EDIT";
export const REPORT_EDIT_CHANGE_ANSWER_VALUE =
  "REPORT_EDIT_CHANGE_ANSWER_VALUE";
export const REPORT_EDIT_MISSING_ANSWERS = "REPORT_EDIT_MISSING_ANSWERS";
export const REPORT_EDIT_START_SENDING = "REPORT_EDIT_START_SENDING";
export const REPORT_EDIT_SENDING_SUCCESS = "REPORT_EDIT_SENDING_SUCCESS";
export const REPORT_EDIT_SENDING_ERROR = "REPORT_EDIT_SENDING_ERROR";
export const REPORT_EDIT_SKIP_STEP_TOGGLE = "REPORT_EDIT_SKIP_STEP_TOGGLE";

import { connect } from "react-redux";
import Component from "./CurrentTemplate.jsx";
import {
  changeInputValue,
  addNextTemplateItem,
  resetView,
  reorderTemplateItems,
  deleteTemplateItem,
  showModal,
} from "../../actions/uiState";
import { saveCurrentTemplate, startRulesEdit } from "../../actions/templates";

const mapStateToProps = state => ({
  uiState: state.reducer.uiState.currentTemplate,
  employees: state.reducer.employees.data,
});

const mapDispatchToProps = {
  changeInputValue,
  addNextTemplateItem,
  resetView,
  saveCurrentTemplate,
  reorderTemplateItems,
  deleteTemplateItem,
  showModal,
  startRulesEdit,
};

const AttachEmployeeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default AttachEmployeeContainer;

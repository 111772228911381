import React, { Component } from "react";
import { Col, PageHeader, Row, Button } from "antd";
import { TeamOutlined, UserAddOutlined } from "@ant-design/icons";

import { SearchBox } from "../common/";
import CurrentEmployeeModal from "./employeeModal/EmployeeModal.redux";
import EmployeesTable from "./employeesTable/EmployeesTable.redux";

class EmployeesView extends Component {
  constructor(props) {
    super(props);
    this.add = this.add.bind(this);
  }

  add() {
    this.props.showModal("CURRENT_EMPLOYEE");
  }

  render() {
    return (
      <React.Fragment>
        <CurrentEmployeeModal />
        <Row>
          <Col span={24}>
            <PageHeader
              title={
                <span>
                  <TeamOutlined style={{ marginRight: "10px" }} />
                  <span>Lokalizacje</span>
                </span>
              }
              extra={[
                <Button
                  type="primary"
                  icon={<UserAddOutlined />}
                  onClick={this.add}
                >
                  Nowa lokalizacja
                </Button>,
                <SearchBox viewName="employees" />,
              ]}
            />
          </Col>
        </Row>
        <Row style={{ padding: "0 20px" }}>
          <Col span={24}>
            <EmployeesTable />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default EmployeesView;

import React, { Component } from "react";
import LoginView from "../login/LoginView.redux";
import RegisterView from "../register/RegisterView.redux";
import PasswordResetView from "../passwordReset/PasswordResetView.redux";
import NewPasswordView from "../newPassword/NewPasswordView.redux";
import { Route, Switch } from "react-router-dom";

class UnauthApp extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <div className="c-unauthApp">
        <Switch>
          <Route path={"/"} exact component={LoginView} />
          <Route path={"/register"} component={RegisterView} />
          <Route path={"/passwordReset"} component={PasswordResetView} />
          <Route path={"/newPassword"} component={NewPasswordView} />
        </Switch>
      </div>
    );
  }
}

export default UnauthApp;

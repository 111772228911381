import { connect } from "react-redux";
import Component from "./TemplatesView.jsx";
import {
  saveCurrentTemplate,
  startTemplateEdit,
  deleteTemplate,
} from "../../actions/templates";
import { showModal } from "../../actions/uiState";

const mapStateToProps = state => ({
  order: state.reducer.templates.order,
  visible: state.reducer.templates.visible,
});

const mapDispatchToProps = {
  saveCurrentTemplate,
  startTemplateEdit,
  deleteTemplate,
  showModal,
};

const AttachEmployeeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default AttachEmployeeContainer;

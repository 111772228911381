
import {validate} from './validation';
import {setViewState} from '../actions/uiState';

export const validateView = (objectToValidate, validationFields, viewName) => dispatch => {
    const fields = Object.values(validationFields(objectToValidate));
    const errors = {};
    fields.forEach(field => {
        const error = validate(
            field.name,
            objectToValidate[field.key],
            field.options
        );
        if (error) errors[field.key] = error;
    });
    dispatch(setViewState(viewName, { errors }));
    return !Object.values(errors).length;
};

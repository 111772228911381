import React from "react";
import classnames from "classnames";
import Icon from "@mdi/react";
import * as Icons from "@mdi/js";


import "./drawer.scss";

const DrawerItem = props => {
  const { item, history, location } = props;
  const { title, icon, path, beforeAction, neverHighlight } = item;
  const className = classnames("c-drawerItem", {
    "c-drawerItem--active":
      location.pathname.includes(item.path) && !neverHighlight,
  });
  const onClick = () => {
    if (beforeAction) beforeAction();
    if (path) history.push(path);
  };
  return (
    <div className={className} onClick={onClick}>
      <Icon className="c-drawerItem__icon" path={Icons[icon]} size="24px" />
      <span className="c-drawerItem__title">{title}</span>
    </div>
  );
};

const Drawer = props => {
  const menuItems = [
    { title: "Szablony", icon: "mdiFileDocumentEdit", path: "/templates", permission: 'TEMPLATES_VIEW:USE'},
    { title: "Lokalizacje", icon: "mdiCity", path: "/employees", permission: 'LOCATIONS_VIEW:USE'},
    { title: "Raporty", icon: "mdiChartAreaspline", path: "/reports", permission: 'REPORTS_VIEW:USE' },
    { title: "Wypełnij raport", icon: "mdiClipboardEditOutline", path: "/fillReport", permission:'FILL_REPORT_VIEW:USE' },
  ];

  const filteredMenuItems = menuItems.filter(item=>props.userPermissions.includes(item.permission));
  const extraItems = [
    // { title: "Ustawienia", icon: "mdiSettings", path:'/settings' },
    {
      title: "Wyloguj",
      icon: "mdiLogoutVariant",
      path: "/",
      beforeAction: props.logOut,
      neverHighlight: true,
    },
  ];
  const { history, location } = props;
  return (
    <div className="c-drawer">
      <div className="c-drawer__logoContainer">
        <div className="c-drawer__logo" />
      </div>
      {filteredMenuItems.map((item, i) => (
        <DrawerItem
          item={item}
          history={history}
          location={history.location}
          key={`menuItem-${i}`}
        />
      ))}
      <div className="c-drawer__spacer" />
      {extraItems.map((item, i) => (
        <DrawerItem
          item={item}
          history={history}
          location={history.location}
          key={`extraItem-${i}`}
        />
      ))}
    </div>
  );
};

export default Drawer;

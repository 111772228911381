import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { v4 } from "uuid";
import { processModifiers } from "../../../utils/misc";
import Button from "../button/Button.jsx";

import "./stepRules.scss";

const StepRules = props => {
  const { modifiers, label, onChange, id, value, rules, stepNo } = props;

  const baseClassName = classNames("c-stepRules", props.className, {
    "c-disabled": stepNo === 0,
  });
  const className = processModifiers(baseClassName, "c-stepRules", modifiers);
  const presetnRules = rules => {
    if (!rules.length) return "Zawsze";
    let result = "";
    rules.forEach((rule, i) => {
      let value = rule.value;
      if (value === true) value = "Tak";
      if (value === false) value = "Nie";
      result += `${!i ? "K" : "k"}rok ${rule.step} ${rule.rule} ${value}`;
      if (i !== rules.length - 1) result += " oraz ";
    });
    return result;
  };
  return (
    <div className={className}>
      <div className="c-stepRules__label">{label}</div>
      <div className="c-stepRules__valueContainer">
        <p className="c-stepRules__rulesText">{presetnRules(rules)}</p>
        <Button
          modifiers={["purple", "small"]}
          onClick={() => {
            props.startRulesEdit(props.stepId);
            props.showModal("STEP_RULES");
          }}
        >
          Zmień
        </Button>
      </div>
    </div>
  );
};

StepRules.defaultProps = {
  modifiers: [],
};

StepRules.propTypes = {};

export default StepRules;

import React, { Component } from "react";
import mousetrap from "mousetrap";
import { Button } from "antd";
import { Link } from "react-router-dom";
import { TextInput } from "../common/";
import "./loginView.scss";

class LoginView extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    mousetrap.bind("enter", this.submit);
  }

  componentWillUnmount() {
    mousetrap.bind("enter", this.submit);
  }

  handleInputChange(event) {
    this.props.changeInputValue("login", event);
  }
  submit() {
    const { email, password, rememberMe } = this.props.uiState;
    this.props.login({ email, password }, rememberMe).then(() => {
      this.props.history.push("/templates");
    });
  }
  render() {
    const { uiState } = this.props;
    const { email, password, error } = uiState;

    return (
      <div className="c-login">
        <div className="c-login__container">
          <div className="c-login__title">Zaloguj się</div>
          <TextInput
            id="email"
            value={email}
            onChange={this.handleInputChange}
            type="email"
            label="E-mail"
            onPressEnter={this.submit}
          />
          <TextInput
            id="password"
            value={password}
            onChange={this.handleInputChange}
            type="password"
            label="Hasło"
            onPressEnter={this.submit}
          />
          {error ? <span className="c-login__errorText">{error}</span> : null}
          <Button
            type="primary"
            modifiers={["purple"]}
            onClick={this.submit}
            className={"loginButton"}
          >
            Zaloguj
          </Button>

          <div className="c-login__passwordResetContainer">
            <Link to="/passwordReset">Zresetuj hasło</Link>
          </div>

          <div className="c-login__registerContainer">
            <span className="c-login__noAccountText">Nie masz konta?</span>
            <br />
            <Link to="/register">Wypróbuj za darmo!</Link>
          </div>
        </div>
      </div>
    );
  }
}

export default LoginView;

import conn from "./connections";
import { post } from "axios";

export const get = (startDate, endDate) =>
  conn.getJSON(`${conn.base_url}/reports?from=${startDate}&to=${endDate}`);

export const getForEdit = reportId =>
  conn.getJSON(`${conn.base_url}/reports/${reportId}`);

export const downloadPdf = reportId =>
  conn.getFile(`${conn.base_url}/reports/${reportId}/pdf`, `${reportId}.pdf`);
export const deleteOne = templateId =>
  conn.deleteJSON(`${conn.base_url}/reports/${templateId}`);

export const sendReport = (reportData, retryNo = 0) => {
  return new Promise((resolve, reject) => {
    conn
      .postJSON(`${conn.base_url}/reports`, reportData)
      .then(result => {
        resolve(result);
      })
      .catch(err => {
        if (retryNo > 1) {
          reject(new Error(err));
          throw err;
        } else {
          console.log("Retring report send", retryNo);
          setTimeout(() => {
            sendReport(reportData, retryNo + 1)
              .then(resolve)
              .catch(e => {
                reject(new Error(e));
              });
          }, 2000);
        }
      });
  });
};

export const saveReportEdit = reportData => {
  return conn.putJSON(`${conn.base_url}/reports`, reportData);
};

export const sendImage = imageData => {
  console.log("Sending image ", { ...imageData });
  return new Promise((resolve, reject) => {
    const formData = new FormData();
    formData.append("name", "file");
    formData.append("filename", "img.jpg");
    formData.append("file", imageData);
    formData.append("type", "image/jpg");
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        "x-access-token": conn.authToken,
      },
    };
    const url = `${conn.base_url}/images?returnUrl=true`;
    post(url, formData, config)
      .then(result => {
        console.log("Result", result);
        resolve(result);
        // if (result && result.respInfo && result.respInfo.status === 200) {
        //     resolve(result);
        // } else {
        //     const status =
        //         result && result.respInfo ? result.respInfo.status || null : null;
        //     const response = result ? result.data : null;
        //     reject({ status, message: response });
        // }
      })
      .catch(reject);
    // RNFetchBlob.config({ timeout: 3000 })
    //     .fetch(
    //         'POST',
    //         `${conn.baseUrl}/images`,
    //         {
    //             'Content-Type': 'multipart/form-data',
    //             'x-access-token': conn.authToken,
    //         },
    //         [
    //             {
    //                 name: 'file',
    //                 filename: 'img.jpg',
    //                 data: RNFetchBlob.wrap(imageData.uri),
    //                 type: 'image/jpg',
    //             },
    //         ],
    //     )
    //
  });
};

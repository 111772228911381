import { connect } from "react-redux";
import Component from "./StepScoreModal.jsx";
import {
  hideModal,
  changeInputValue,
  resetView,
} from "../../../actions/uiState";

import {
  initNewScoreRule,
  deleteScoreRule,
  changeScoreRuleValue,
  saveScoreRules,
} from "../../../actions/scoreRules";

const mapStateToProps = state => ({
  uiState: state.reducer.uiState.stepScoreModal,
  stepsState: state.reducer.uiState.currentTemplate,
});

const mapDispatchToProps = {
  changeInputValue,
  hideModal,
  resetView,
  initNewScoreRule,
  changeScoreRuleValue,
  deleteScoreRule,
  saveScoreRules,
};

const AttachEmployeeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default AttachEmployeeContainer;

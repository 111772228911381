import { push } from "connected-react-router";
import { sleep } from "../utils/misc";
import { validate } from "../utils/validation";
import { setViewState, resetView } from "./uiState";
import { requestPasswordReset, setNewPassword } from "../api/passwordReset";
import { registerValidationFields, validateRegisterObject } from "./register";
import { validateView } from "../utils/validationHelpers";

export const newPasswordValidationFields = allFields => ({
  password: { name: "password", key: "password", options: { required: true } },
  confirmPassword: {
    name: "confirmPassword",
    key: "confirmPassword",
    options: { required: true, valueToCompare: allFields.password },
  },
});

const startResetPasswordViewLoader = () => dispatch => {
  dispatch(setViewState("resetPassword", { state: "loading" }));
};

const showResetPasswordViewConfirm = () => dispatch => {
  dispatch(setViewState("resetPassword", { state: "success" }));
};

const startNewPasswordViewLoader = () => dispatch => {
  dispatch(setViewState("newPassword", { state: "loading" }));
};

const showNewPasswordViewConfirm = () => dispatch => {
  dispatch(setViewState("newPassword", { state: "success" }));
};

export const resetPassword = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const { resetPassword } = getState().reducer.uiState;
    const { email } = resetPassword;
    const error = validate("email", email, { required: true });
    if (error) {
      dispatch(setViewState("resetPassword", { errors: { email: error } }));
      reject();
    } else {
      dispatch(startResetPasswordViewLoader());
      console.log("error", error);
      console.log("Reset password state", resetPassword);
      requestPasswordReset(email).then(result => {
        resolve(result);
        dispatch(showResetPasswordViewConfirm());
        setTimeout(() => {
          dispatch(resetView("resetPassword"));
          dispatch(push("/"));
        }, 3000);
      });
    }
  });
};

export const saveNewPassword = token => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const { newPassword: newPasswordData } = getState().reducer.uiState;
    console.log("REG", newPasswordData);
    const isObjectValid = dispatch(
      validateView(newPasswordData, newPasswordValidationFields, "newPassword")
    );
    if (!isObjectValid) {
      reject();
      return;
    }
    dispatch(startNewPasswordViewLoader());
    setNewPassword(newPasswordData.password, token)
      .then(result => {
        resolve(result);
        dispatch(showNewPasswordViewConfirm());
        setTimeout(() => {
          dispatch(resetView("newPassword"));
          dispatch(push("/"));
        }, 3000);
      })
      .catch(reject);
  });
};

import * as AT from "../constants/actionTypes";

const initialState = {
    authenticated: false,
    token: '',
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case AT.AUTH_USER_SUCCESS:{
            const {token, user:userObject} = action.payload;
            const {role} = userObject;
            const user = {...userObject, role: undefined};
            return {...state,authenticated: true, token, user, role}
        }
        case AT.AUTH_USER_LOGOUT: {
            return {...initialState};
        }
        default:
            return state;
    }
};

export default reducer;

import { connect } from "react-redux";
import Component from "./StepRulesModal.jsx";
import { hideModal, changeInputValue, resetView } from "../../actions/uiState";
import {
  initNewRule,
  changeRuleValue,
  deleteRule,
  saveRules,
} from "../../actions/templates";

const mapStateToProps = state => ({
  uiState: state.reducer.uiState.stepRulesModal,
  stepsState: state.reducer.uiState.currentTemplate,
});

const mapDispatchToProps = {
  changeInputValue,
  hideModal,
  resetView,
  initNewRule,
  changeRuleValue,
  deleteRule,
  saveRules,
};

const AttachEmployeeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default AttachEmployeeContainer;

import { connect } from "react-redux";
import Component from "./DateRangeInput.jsx";
import { changeDateRange } from "../../../actions/uiState";

const mapStateToProps = (state, ownProps) => ({
  dateRange: state.reducer.dateRange,
});

const mapDispatchToProps = {
  changeDateRange,
};

const AttachEmployeeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default AttachEmployeeContainer;

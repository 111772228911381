import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
// import Select from "react-select";
import { Select } from "antd";
import "react-select/dist/react-select.css";
import "./selectInput.scss";
import { processModifiers } from "../../../utils/misc";

const SelectInput = props => {
  const { modifiers, options, value, id, onChange, label } = props;
  const disabled = props.disabled || modifiers.includes("disabled");
  const baseClassName = classNames("c-button", props.className, {
    "c-button--disabled": disabled,
  });
  const className = processModifiers(baseClassName, "c-button", modifiers);

  return (
    <div className="c-selectInput">
      <span className="c-selectInput__label">{label}</span>
      <Select
        options={options}
        value={value.value}
        onChange={value => {
          onChange({ target: { id, value: { value }, type: "select" } });
        }}
      />
    </div>
  );
};

SelectInput.defaultProps = {
  type: "button",
  modifiers: [],
};

SelectInput.propTypes = {
  className: PropTypes.string,
  modifiers: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  type: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node,
  label: PropTypes.node,
};

export default SelectInput;

import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {processModifiers} from "../../../utils/misc";

import './button.scss';

const Button = props => {
  const {modifiers} = props;
  const disabled = props.disabled || modifiers.includes('disabled');
  const baseClassName = classNames('c-button', props.className, {
    'c-button--disabled': disabled,
  });
  const className = processModifiers(baseClassName,'c-button',modifiers)
  return (
    <button
      type={props.type}
      id={props.id}
      className={className}
      onClick={e => !disabled && props.onClick && props.onClick(e)}
    >
      {props.children}
    </button>
  );
};

Button.defaultProps = {
  type: 'button',
  modifiers: [],
};

Button.propTypes = {
  className: PropTypes.string,
  modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
  type: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

export default Button;

import moment from "moment";
import * as AT from "../constants/actionTypes";

const initialState = {
  currentRoute: "/",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AT.SET_CURRENT_ROUTE: {
      return { ...state, currentRoute: action.payload.route };
    }
    case AT.AUTH_USER_LOGOUT: {
      return { ...initialState };
    }
    default:
      return state;
  }
};

export default reducer;

import React, { Component } from "react";
import fieldTypes from "../../constants/templateFieldTypes";
import { Popover, List, Row, Col, PageHeader, Button, Switch } from "antd";
import {
  TextInput,
  SelectInput,
  MultiselectInput,
  Toggle,
  StepRules,
  ScoreNamesInput
} from "../common/";
import {
  FontSizeOutlined,
  CalculatorOutlined,
  StarOutlined,
  CameraOutlined,
  ReconciliationOutlined,
  FileAddOutlined,
  RollbackOutlined,
  SaveOutlined
} from "@ant-design/icons";
import TopBar from "../topBar/TopBar.redux";
import Icon from "@mdi/react";
import {
  mdiPlus,
  mdiCursorMove,
  mdiDeleteForever,
  mdiPanVertical
} from "@mdi/js";
import StepRulesModal from "./StepRulesModal.redux";
import StepScoreModal from "./stepScoreModal/StepScoreModal.redux";
import TemplateItem from "./TemplateItem.redux";
import TemplatesTable from "../templatesView/templatesTable/TemplatesTable.redux";

class CurrentTemplate extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.save = this.save.bind(this);
    this.cancel = this.cancel.bind(this);
  }

  handleInputChange(path, event) {
    this.props.changeInputValue("currentTemplate", event, path);
  }

  componentWillUnmount() {
    this.props.resetView("currentTemplate");
  }

  async save() {
    await this.props.saveCurrentTemplate();
    this.props.history.push("/templates");
  }

  cancel() {
    this.props.history.push("/templates");
  }

  render() {
    const data = [
      { label: "Tekst", icon: <FontSizeOutlined />, color: "#faad14" },
      { label: "Liczba", icon: <CalculatorOutlined />, color: "#1890ff" },
      { label: "Ocena", icon: <StarOutlined />, color: "#B00B55" },
      { label: "Zdjęcia", icon: <CameraOutlined />, color: "#faad14" },
      { label: "Lista wyboru", icon: <CalculatorOutlined />, color: "#1890ff" },
      { label: "Tak/Nie", icon: <StarOutlined />, color: "#B00B55" }
    ];
    const { addNextTemplateItem, uiState, employees } = this.props;
    const { info, steps, stepsOrder, assignedEmployees } = uiState;
    const {
      name,
      requireAuthorName,
      description,
      notifyEmail,
      rateReport,
      allowGalleryPhotos,
      maxRateScore,
      scoreNames,
      showPoints,
      showComments,
      pointsPerSection
    } = info;
    const assignEmployeesOptions = Object.values(employees).map(e => ({
      label: `${e.last_name} - ${e.first_name}`,
      value: e._id
    }));

    return (
      <React.Fragment>
        <Row>
          <Col span={24}>
            <PageHeader
              title={
                <span>
                  <FileAddOutlined style={{ marginRight: "10px" }} />
                  <span>Nowy szablon</span>
                </span>
              }
              extra={[
                <Button
                  type="primary"
                  icon={<RollbackOutlined />}
                  onClick={this.cancel}
                >
                  Anuluj
                </Button>,
                <Button
                  type="primary"
                  icon={<SaveOutlined />}
                  onClick={this.save}
                >
                  Zapisz
                </Button>
              ]}
            />
          </Col>
        </Row>
        <Row style={{ padding: "0 20px" }}>
          <Col span={24}>
            <div className="c-templatesView">
              <StepRulesModal />
              <StepScoreModal />

              <div className="c-contentContainer">
                <div className="c-flexColumns">
                  <TextInput
                    id="name"
                    value={name}
                    type="text"
                    label="Nazwa szablonu"
                    onChange={this.handleInputChange.bind(this, "info")}
                  />
                  <MultiselectInput
                    options={assignEmployeesOptions}
                    onChange={this.handleInputChange.bind(this, null)}
                    id="assignedEmployees"
                    label="Przypisane lokalizacje"
                    value={assignedEmployees}
                  />
                  <TextInput
                    id="description"
                    value={description}
                    type="text"
                    label="Opis szablonu"
                    onChange={this.handleInputChange.bind(this, "info")}
                  />
                  <Toggle
                    id="requireAuthorName"
                    checked={requireAuthorName}
                    onChange={this.handleInputChange.bind(this, "info")}
                    label={"Pytanie o autora"}
                  />
                  <TextInput
                    id="notifyEmail"
                    value={notifyEmail}
                    type="text"
                    label="E-mail do notyfikacji"
                    onChange={this.handleInputChange.bind(this, "info")}
                  />
                  <Toggle
                    id="rateReport"
                    checked={rateReport}
                    onChange={this.handleInputChange.bind(this, "info")}
                    label={"Generuj ocenę raportu"}
                  />
                  <Toggle
                    id="allowGalleryPhotos"
                    checked={allowGalleryPhotos}
                    onChange={this.handleInputChange.bind(this, "info")}
                    label={"Dodawanie zdjęć z galerii"}
                  />
                  <Toggle
                    id="showComments"
                    checked={showComments}
                    onChange={this.handleInputChange.bind(this, "info")}
                    label={"Pokarz komentarze w raporcie"}
                  />
                  {rateReport ? (
                    <>
                      <TextInput
                        id="maxRateScore"
                        value={maxRateScore}
                        type="number"
                        label="Wynik maksymalny"
                        onChange={this.handleInputChange.bind(this, "info")}
                      />
                      <ScoreNamesInput
                        id="scoreNames"
                        value={scoreNames}
                        label="Punktacja"
                        onChange={this.handleInputChange.bind(this, "info")}
                      />
                      <Toggle
                        id="showPoints"
                        checked={showPoints}
                        onChange={this.handleInputChange.bind(this, "info")}
                        label={"Pokaż punkty za pytania"}
                      />
                      <Toggle
                        id="pointsPerSection"
                        checked={pointsPerSection}
                        onChange={this.handleInputChange.bind(this, "info")}
                        label={"Licz punkty w sekcjach"}
                      />
                    </>
                  ) : null}
                </div>
                {stepsOrder.map((index, no) => (
                  <TemplateItem
                    stepId={index}
                    stepNo={no}
                    ratedReport={rateReport}
                    key={index}
                    pointsPerSection={pointsPerSection}
                  />
                ))}
                <div className="c-addTemplateItemButton__container">
                  <div
                    className="c-addTemplateItemButton"
                    role="button"
                    onClick={addNextTemplateItem}
                  >
                    <Icon
                      path={mdiPlus}
                      className="c-addTemplateItemButton__icon"
                      size="45px"
                    />
                  </div>
                </div>
              </div>
            </div>
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default CurrentTemplate;

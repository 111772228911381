import React from "react";
import { Rate } from "antd";
import { FrownOutlined, MehOutlined, SmileOutlined } from "@ant-design/icons";

const customIcons = {
  1: <FrownOutlined />,
  2: <FrownOutlined />,
  3: <MehOutlined />,
  4: <SmileOutlined />,
  5: <SmileOutlined />,
};

const RatingStep = ({ value, onChange }) => {
  return (
    <Rate
      defaultValue={3}
      character={({ index }) => customIcons[index + 1]}
      style={{ fontSize: 42 }}
      value={value}
      onChange={onChange}
    />
  );
};

export default RatingStep;

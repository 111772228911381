import * as AT from "../constants/actionTypes";
import { createPromiseAction } from "../utils/misc";
import { setCookie, deleteCookie } from "../utils/cookieHelpers";
import * as Auth from "../api/auth";
import { getEmployees } from "./employees";
import { getTemplates } from "./templates";

export const login = createPromiseAction(
  Auth.authenticate,
  (result, args, dispatch) => {
    const { token, user } = result.data.data;
    if (user.role.permissions.includes("MANAGER_APP:USE")) {
      dispatch(authenticationSuccess(token, user));
    }
  },
  (result, _, dispatch) => dispatch(authenticationError(result))
);

export const authenticationSuccess = (token, user) => dispatch => {
  dispatch({ type: AT.AUTH_USER_SUCCESS, payload: { token, user } });
  Auth.setAuthToken(token);
  setCookie("authToken", token);
  dispatch(getInitData());
};

export const authenticationError = result => dispatch => {
  const { status, data } = result.response;
  let errorMessage = "Nie udało się zalogować";
  if (status === 404 && data.message === "Invalid email/password!!!") {
    errorMessage = "Zły adres e-mail lub hasło";
  }
  dispatch({ type: AT.AUTH_USER_ERROR, payload: errorMessage });
};

export const reLogin = token => dispatch => {
  Auth.setAuthToken(token);
  Auth.getCurrentUserData().then(response => {
    dispatch(authenticationSuccess(token, response.data));
  });
};

export const getInitData = () => dispatch => {
  // dispatch(getEmployees());
  // dispatch(getTemplates());
};

export const logOut = () => dispatch => {
  dispatch({ type: AT.AUTH_USER_LOGOUT });
  Auth.setAuthToken("");
  deleteCookie("authToken");
};

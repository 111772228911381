import conn from "./connections";

export const get = () => conn.getJSON(`${conn.base_url}/templates`);
export const add = template =>
  conn.postJSON(`${conn.base_url}/templates`, template);
export const update = template =>
  conn.putJSON(`${conn.base_url}/templates`, template);
export const deleteOne = templateId =>
  conn.deleteJSON(`${conn.base_url}/templates/${templateId}`);
export const importFile = data =>
  conn.postJSON(`${conn.base_url}/templates/import`, data);
export const getAvailableTemplates = () =>
    conn.getJSON(`${conn.base_url}/templates/assigned`);

import { connect } from "react-redux";
import Component from "./routes.jsx";
import { loadDataForView } from "../../actions/viewData";

const mapStateToProps = state => ({
  authenticated: state.reducer.auth.authenticated,
});

const mapDispatchToProps = {
  loadDataForView,
};

const AttachEmployeeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default AttachEmployeeContainer;

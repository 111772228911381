import ConnectionManager from "./ConnectionManager";

const targets = {
  local: {
    core: "http://localhost:3001",
  },
  legacy: {
    core: "http://52.58.1.239:3001",
  },
  production: {
    core: "https://api.reportio.pl",
  },
};

const conn = new ConnectionManager(targets.production);

export default conn;

import React, { Component } from "react";
import { notification } from "antd";
import { TextInput, Modal } from "../../common/";
import "./currentEmployee.scss";

class Employees extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.cancel = this.cancel.bind(this);
    this.save = this.save.bind(this);
    this.resetView = this.resetView.bind(this);
  }

  handleInputChange(event) {
    this.props.changeInputValue("currentEmployee", event);
  }
  resetView() {
    this.props.resetView("currentEmployee");
  }
  save() {
    this.props
      .saveCurrentEmployee()
      .then(() => {
        this.props.hideModal();
        if (this.props.uiState.id) {
          this.didEdit = true;
        } else {
          this.didAdd = true;
        }
      })
      .catch(() => {});
  }
  cancel() {
    this.props.hideModal();
  }

  render() {
    const {
      loading,
      firstName,
      lastName,
      email,
      password,
      id,
      notificationEmail,
      errors,
    } = this.props.uiState;

    return (
      <Modal
        name="CURRENT_EMPLOYEE"
        title={id ? "Edycja lokalizację" : "Nowa lokalizacja"}
        cancelText="Anuluj"
        okText={id ? "Zapisz" : "Dodaj"}
        onCancel={this.cancel}
        onOk={this.save}
        confirmLoading={loading}
        cancelButtonProps={{ disabled: loading }}
        afterClose={this.resetView}
      >
        <div className="c-currentEmployee__contentWrapper">
          <div className="c-flex c-flex--vertical">
            <TextInput
              id="firstName"
              value={firstName}
              onChange={this.handleInputChange}
              type="text"
              label="Nazwa"
              inputClassName="mousetrap"
              error={errors["firstName"]}
              required
            />
            <TextInput
              id="lastName"
              value={lastName}
              onChange={this.handleInputChange}
              type="text"
              label="Miasto"
              inputClassName="mousetrap"
              error={errors["lastName"]}
              required
            />
            <TextInput
              id="email"
              value={email}
              onChange={this.handleInputChange}
              type="email"
              label="E-mail"
              inputClassName="mousetrap"
              error={errors["email"]}
              required
            />
            <TextInput
              id="notificationEmail"
              value={notificationEmail}
              onChange={this.handleInputChange}
              type="email"
              error={errors["notificationEmail"]}
              label="Adresy e-mail do notyfikacji"
              inputClassName="mousetrap"
            />
            <TextInput
              id="password"
              value={password}
              onChange={this.handleInputChange}
              type="password"
              label={id ? "Nowe hasło" : "Hasło"}
              error={errors["password"]}
              inputClassName="mousetrap"
              required={!id}
            />
          </div>
        </div>
      </Modal>
    );
  }
}

export default Employees;

import { connect } from "react-redux";
import Component from "./CreateReportView.jsx";
import { submitReport } from "../../actions/ReportView";

const mapStateToProps = state => ({
  templateId: state.reducer.uiState.reportView.templateId,
  isSending: state.reducer.uiState.reportView.sending,
  hiddenSteps: state.reducer.uiState.reportView.hiddenSteps,
  template:
    state.reducer.availableTemplates.data[
      state.reducer.uiState.reportView.templateId
    ],
});

const mapDispatchToProps = {
  submitReport,
};

const Container = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Container;

import { connect } from "react-redux";
import Component from "./RegisterView.jsx";
import { changeInputValue } from "../../actions/uiState";
import { login } from "../../actions/auth";
import {registerNewAccount} from "../../actions/register";

const mapStateToProps = state => ({
  uiState: state.reducer.uiState.register,
});

const mapDispatchToProps = {
  changeInputValue,
  login,
  registerNewAccount,
};

const Container = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Container;

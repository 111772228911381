import * as AT from "../../constants/actionTypes";

const initialState = {
  file: null,
  dropActive: false,
  showError: false,
};

const reducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case AT.CHANGE_INPUT_VALUE: {
      if (action.payload.view === "importTemplate") {
        const { name, value } = action.payload;
        return { ...state, [name]: value };
      }
      return state;
    }
    case AT.RESET_VIEW_STATE: {
      if (action.payload === "importTemplate") return { ...initialState };
      return state;
    }
    case AT.SET_VIEW_STATE: {
      const { viewName, data } = action.payload;
      if (viewName === "importTemplate") return { ...state, ...data };
      return state;
    }
    default:
      return state;
  }
};

export default reducer;

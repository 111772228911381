import React, { Component } from "react";
import { Button, Table, ConfigProvider, Popconfirm, Empty, Tag } from "antd";
import {
  DeleteTwoTone,
  EditTwoTone,
  WarningTwoTone,
  CopyTwoTone,
} from "@ant-design/icons";
import { Label } from "../../common";

class AvailableTemplatesTable extends Component {
  constructor(props) {
    super(props);
  }

  async edit(template) {
    const { startTemplateEdit, history } = this.props;
    await startTemplateEdit(template._id);
    history.push("/templates/current");
  }
  async duplicate(template) {
    const { startTemplateEdit, history } = this.props;
    await startTemplateEdit(template._id, true);
    history.push("/templates/current");
  }

  delete(id) {
    this.props.deleteTemplate(id);
  }

  render() {
    const { availableTemplates } = this.props;
    const { order, data, loading } = availableTemplates;

    const processedTemplates = order.map(id => {
      const template = data[id];
      return template;
    });

    return (
      <ConfigProvider
        renderEmpty={() => {
          if (loading) return <div className="loadingEmptySpace" />;
          return <Empty description={<p>Brak raportów do wyświetlenia</p>} />;
        }}
      >
        <Table
          bordered
          loading={loading}
          size={"small"}
          pagination={{
            pageSize: 20,
            hideOnSinglePage: true,
            pageSizeOptions: ["5", "20", "50", "100", "200"],
            showSizeChanger: true,
            locale: { items_per_page: "" },
            showTotal: (total, range) => {
              return `${range[0]} - ${range[1]} z ${total} szablonów`;
            },
          }}
          dataSource={processedTemplates}
          columns={[
            {
              title: "Nazwa",
              align: "left",
              sorter: (a, b) => a.info.name.localeCompare(b.info.name),
              render: template => template.info.name,
            },
            {
              title: "Opcje",
              align: "center",
              width: "350px",
              render: template => (
                <React.Fragment>
                  <Button
                    icon={<EditTwoTone />}
                    type={"link"}
                    onClick={() => {
                      this.props.startNewReportCreation(template._id);
                      this.props.history.push(`/fillReport/${template._id}`);
                    }}
                  >
                    Wypełnij
                  </Button>
                  <Button
                    icon={<EditTwoTone />}
                    type={"link"}
                    onClick={() => {
                      this.props.startNewReportCreation(template._id);
                      this.props.history.push(`/createReport`);
                    }}
                  >
                    Wypełnij (nowe)
                  </Button>
                </React.Fragment>
              ),
            },
          ]}
        />
      </ConfigProvider>
    );
  }
}

export default AvailableTemplatesTable;

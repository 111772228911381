import React from 'react';
import { PropTypes } from 'prop-types';
import classnames from 'classnames';
import Icon from "@mdi/react";
import { mdiClose } from "@mdi/js";
import * as Icons from "@mdi/js";

const ModalHeader = (props, context) => {
  const className = classnames('kmodal__header', { 'kmodal__header--closeButton': props.closeButton });
  return (
    <div className={className}>
      {props.children}
      {props.closeButton ? (
          <Icon
              className="kmodal__closeIcon"
              onClick={context.onHide}
              path={mdiClose}
              size="24px"
          />
      ) : null}
    </div>
  );
};
ModalHeader.contextTypes = {
  onHide: PropTypes.func,
};
ModalHeader.propTypes = {
  closeButton: PropTypes.bool,
  children: PropTypes.node,
};
export default ModalHeader;

import React from "react";
import { Switch } from "antd";

const BooleanStep = ({ value, onChange }) => {
  console.log("Checked", value);
  return (
    <Switch
      checkedChildren="Tak"
      unCheckedChildren="Nie"
      defaultChecked
      checked={value}
      onChange={onChange}
    />
  );
};

export default BooleanStep;

import { connect } from "react-redux";
import Component from "./NewReportView.jsx";
import {
  saveCurrentTemplate,
  startTemplateEdit,
  deleteTemplate,
} from "../../actions/templates";
import { showModal, changeInputValue } from "../../actions/uiState";
import {startNewReportCreation, newReportGoToStep, submitNewReport, sendReport} from "../../actions/newReport";

const mapStateToProps = (state, ownProps) => ({
  uiState: state.reducer.uiState.newReport,
  template: state.reducer.availableTemplates.data[ownProps.match.params.id] || null,
});

const mapDispatchToProps = {
  saveCurrentTemplate,
  startTemplateEdit,
  deleteTemplate,
  showModal,
  startNewReportCreation,
  changeInputValue, newReportGoToStep, submitNewReport, sendReport
};

const AttachEmployeeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default AttachEmployeeContainer;

import { connect } from "react-redux";
import Component from "./ReportsTable.jsx";
import { getReportPdf, deleteReport } from "../../../actions/reports";
import { startReportEdit } from "../../../actions/ReportView";

const mapStateToProps = (state, props) => ({
  templates: state.reducer.templates,
  reports: state.reducer.reports,
  employees: state.reducer.employees,
});

const mapDispatchToProps = {
  getReportPdf,
  deleteReport,
  editReport: startReportEdit,
};

const AttachEmployeeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default AttachEmployeeContainer;

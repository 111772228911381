import * as AT from "../constants/actionTypes";
import { getAvailableTemplates, getTemplates } from "./templates";
import { getReports } from "./reports";
import { getEmployees } from "./employees";

export const loadDataForView = (path, lastPath) => async (
  dispatch,
  getState
) => {
  const { dateRange } = getState().reducer;
  dispatch({ type: AT.SET_CURRENT_ROUTE, payload: { route: path } });
  let mainPath = path.split("/")[1];
  switch (mainPath) {
    case "templates": {
      if (lastPath !== "/templates/current" && path === "/templates") {
        dispatch(getEmployees());
        dispatch(getTemplates());
      }
      break;
    }
    case "reports": {
      dispatch(getReports(dateRange.start, dateRange.end));
      dispatch(getEmployees());
      break;
    }
    case "employees": {
      dispatch(getEmployees());
      break;
    }
    case "createReport":
    case "fillReport": {
      dispatch(getAvailableTemplates());
      break;
    }
    default:
      break;
  }
};

export const dateChangeMiddleware = store => next => action => {
  const { nav } = store.getState().reducer;
  const { currentRoute } = nav;
  switch (action.type) {
    case AT.CHANGE_DATE_RANGE:
      const returnValue = next(action);
      const { dateRange } = store.getState().reducer;
      const { start, end } = dateRange;
      if (currentRoute === "/reports") {
        store.dispatch(getReports(start, end));
      }
      return returnValue;
    default:
      return next(action);
  }
};

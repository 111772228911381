import React from "react";
import { IntlProvider, intlShape, addLocaleData } from "react-intl";
import renderer from "react-test-renderer";

import pl from "react-intl/locale-data/pl";

import { getCookie } from "./cookieHelpers.js";
const localeData = { pl: {} };

addLocaleData([...pl]);
const intlProps = { locale: "pl", messages: localeData.pl };
const intlProvider = new IntlProvider(intlProps, {});
const { intl } = intlProvider.getChildContext();

// Define user's language. Different browsers have the user locale defined
// on different fields on the `navigator` object, so we make sure to account
// for these different by checking all of them
export const getDefaultLang = () =>
  getCookie("kadroLang") ||
  (navigator.languages && navigator.languages[0]) ||
  navigator.language ||
  navigator.userLanguage;

const language = getDefaultLang();

// Split locales with a region code
let languageWithoutRegionCode = language.toLowerCase().split(/[_-]+/)[0];

// Try full locale, try locale without region code
let messages = localeData[languageWithoutRegionCode] || localeData[language];

export function getInitialIntl() {
  if (!messages) {
    languageWithoutRegionCode = "pl";
    messages = localeData.pl;
  }
  return {
    intl: {
      locale: languageWithoutRegionCode,
      messages,
      formatMessage: intl.formatMessage
    }
  };
}



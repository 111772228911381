import React from "react";
import classNames from "classnames";
import { processModifiers } from "../../../utils/misc";

import "./label.scss";

const Label = props => {
  const { modifiers, title } = props;
  const baseClassName = classNames("c-label", props.className);
  const className = processModifiers(baseClassName, "c-label", modifiers);
  return (
    <span className={className}>
      <span className="c-label__title">{title}</span>
    </span>
  );
};

Label.defaultProps = {
  modifiers: []
};

Label.propTypes = {};

export default Label;

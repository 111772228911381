import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { processModifiers } from "../../../utils/misc";
import { Input, Tooltip } from "antd";
import { QuestionCircleOutlined, CloseCircleFilled } from "@ant-design/icons";

import "./textInput.scss";

const TextInput = props => {
  const {
    modifiers,
    label,
    type,
    onChange,
    id,
    value,
    error,
    required,
    onPressEnter,
    helpPopupContent,
    addonBefore,
  } = props;
  const disabled = props.disabled || modifiers.includes("disabled");
  const baseClassName = classNames("c-textInput", props.className, {
    "c-textInput--disabled": disabled,
    "c-textInput--error": error,
    "c-textInput--required": required,
  });
  const inputClassName = classNames("c-textInput__input", props.inputClassName);
  const className = processModifiers(baseClassName, "textInput", modifiers);
  const InputComponent = type === "password" ? Input.Password : Input;
  return (
    <div className={className}>
      <div className="c-textInput__label">
        {label}
        {helpPopupContent ? (
          <Tooltip title={helpPopupContent}>
            <QuestionCircleOutlined style={{ color: "#666" }} />
          </Tooltip>
        ) : null}
      </div>
      <InputComponent
        value={value}
        onChange={onChange}
        id={id}
        inputClassName={inputClassName}
        type={type}
        onPressEnter={onPressEnter}
        addonBefore={addonBefore}
        suffix={
          error ? (
            <CloseCircleFilled className="c-textInput__errorIcon" />
          ) : (
            <span />
          )
        }
      />
      {error ? <div className="c-textInput__error">{error}</div> : null}
    </div>
  );
};

TextInput.defaultProps = {
  type: "text",
  modifiers: [],
};

TextInput.propTypes = {
  className: PropTypes.string,
  modifiers: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  type: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

export default TextInput;

import React from "react";
import { InputNumber } from "antd";

const NumberStep = ({ value, onChangeText, onBlur }) => {
  return (
    <InputNumber
      onChange={onChangeText}
      value={value}
      style={{ width: "200px" }}
      onBlur={onBlur}
    />
  );
};

export default NumberStep;

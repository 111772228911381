import { v4 } from "uuid";
import * as AT from "../constants/actionTypes";

const formatTemplate = t => {
  const id = t._id;
  const stepsOrder = [];
  const steps = {};
  t.steps.forEach((s, i) => {
    const index = `i-${i + 1}`;
    stepsOrder.push(index);
    steps[index] = { ...s };
  });
  const template = { ...t, steps, stepsOrder };
  return { template, id };
};

const calculateVisible = state => {
  const { data, searchBox } = state;
  return Object.values(data)
    .filter(item => !searchBox || JSON.stringify(item).includes(searchBox))
    .map(item => item._id);
};

const initialState = {
  data: {},
  order: [],
  visible: [],
  searchBox: "",
  sorting: { key: "index", decr: false },
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AT.SAVE_CURRENT_TEMPLATE: {
      const uuid = v4();
      return {
        ...state,
        data: { ...state.data, [uuid]: action.payload },
        order: [...state.order, uuid],
      };
    }
    case AT.GET_TEMPLATES_SUCCESS: {
      const data = {};
      const order = [];
      action.payload.forEach(t => {
        const { template, id } = formatTemplate(t);
        data[id] = template;
        order.push(id);
      });
      return { ...state, data, order };
    }
    case AT.ADD_TEMPLATE_SUCCESS: {
      const { template, id } = formatTemplate(action.payload);
      return {
        ...state,
        data: { ...state.data, [id]: template },
        order: [...state.order, id],
      };
    }
    case AT.UPDATE_TEMPLATE_SUCCESS: {
      const { template, id } = formatTemplate(action.payload);
      return {
        ...state,
        data: { ...state.data, [id]: template },
      };
    }
    case AT.DELETE_TEMPLATE_SUCCESS: {
      const newTemplates = { ...state.data };
      delete newTemplates[action.payload];
      return {
        ...state,
        data: newTemplates,
        order: state.order.filter(id => id !== action.payload),
      };
    }
    case AT.CHANGE_SEARCH_VALUE: {
      const { value, view } = action.payload;
      if (view === "templates") {
        return { ...state, searchBox: value };
      }
      return state;
    }
    case AT.UPDATE_VISIBLE_ELEMENTS: {
      if (action.payload.view === "templates") {
        return { ...state, visible: action.payload.visible };
      }
      return state;
    }
    case AT.UPDATE_ELEMENTS_ORDER: {
      if (action.payload.view === "templates") {
        return {
          ...state,
          order: action.payload.order,
          sorting: action.payload.sorting,
        };
      }
      return state;
    }
    case AT.CHANGE_VIEW_LOADING_STATUS: {
      if (action.payload.view === "templates") {
        return { ...state, loading: action.payload.isLoading };
      }
      return state;
    }

    default:
      return state;
  }
};

export default reducer;

import { connect } from "react-redux";
import Component from "./TopBar.jsx";

const mapStateToProps = state => ({
});

const mapDispatchToProps = {

};

const AttachEmployeeContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);

export default AttachEmployeeContainer;

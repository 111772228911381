import React, { Component } from "react";
import { Modal } from "antd";
import "./modal.scss";

class RModal extends Component {
  render() {
    return <Modal {...this.props}>{this.props.children}</Modal>;
  }
}

export default RModal;

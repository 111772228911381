import { connect } from "react-redux";
import Component from "./AuthApp.jsx";

const mapStateToProps = state => ({
    userPermissions: state.reducer.auth.role?state.reducer.auth.role.permissions:[],
});

const mapDispatchToProps = {
};

const Container = connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);

export default Container;

import React, { Component } from "react";
import mousetrap from "mousetrap";
import { Button } from "antd";
import { UnAuthAppBox } from "../common/index";
import { TextInput } from "../common/";
import "./passwordResetView.scss";

class PasswordResetView extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    mousetrap.bind("enter", this.submit);
  }

  componentWillUnmount() {
    mousetrap.bind("enter", this.submit);
  }

  handleInputChange(event) {
    this.props.changeInputValue("resetPassword", event);
  }
  submit() {
    this.props.resetPassword();
  }
  render() {
    const { uiState } = this.props;
    const { email, errors, state: resetState } = uiState;
    const showSuccessMessage = resetState === "success";
    if (showSuccessMessage) {
      return (
        <div className="c-login">
          <UnAuthAppBox>
            <div className="c-passwordReset__title">Sprawdź pocztę e-mail</div>
            <p className="c-passwordReset__infoText">
              Na podany adres wysłaliśmy wiadomość z dalszymi instrukcjami
              dotyczącymi resetu hasła.
            </p>
          </UnAuthAppBox>
        </div>
      );
    }

    const loading = resetState === "loading";
    return (
      <div className="c-login">
        <UnAuthAppBox>
          <div className="c-passwordReset__title">Reset hasła</div>
          <p className="c-passwordReset__infoText">
            W celu zresetowania zapomnianego hasła proszę podać adres email
            powiązany z kontem. Na podany adres email zostanie wysłana wiadomość
            z linkiem do ustawienia nowego hasła. Link będzie ważny przez 24
            godziny od wysłania.
          </p>
          <TextInput
            id="email"
            value={email}
            onChange={this.handleInputChange}
            type="email"
            label="E-mail"
            inputClassName="mousetrap"
            error={errors["email"]}
          />

          <Button
            loading={loading}
            type="primary"
            modifiers={["purple"]}
            onClick={this.submit}
            className={"loginButton"}
          >
            {loading ? "Resetuję hasło" : "Zresetuj hasło"}
          </Button>
        </UnAuthAppBox>
      </div>
    );
  }
}

export default PasswordResetView;

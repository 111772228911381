import { connect } from "react-redux";
import Component from "./SearchBox.jsx";
import { changeSearchValue } from "../../../actions/uiState";

const mapStateToProps = (state, ownProps) => ({});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onSearch: value => {
      dispatch(changeSearchValue(ownProps.viewName, value));
    },
  };
};

const AttachEmployeeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default AttachEmployeeContainer;

import * as AT from "../constants/actionTypes";
import { calculateVisibleTemplates, sortTemplates } from "./templates";
import { calculateVisibleReports, sortReports } from "./reports";
import { calculateVisibleEmployees } from "./employees";

export const changeInputValue = (view, event, path) => dispatch => {
  const { target } = event;
  let value, id;
  if (target.type === "checkbox") {
    value = target.checked;
    id = target.name;
  } else {
    ({ value, id } = target);
  }
  dispatch({
    type: AT.CHANGE_INPUT_VALUE,
    payload: { view, name: id, value, path: path ? path.split(".") : null },
  });
};

let viewFilterTimeout;

export const changeSearchValue = (view, value) => dispatch => {
  if (viewFilterTimeout) clearTimeout(viewFilterTimeout);
  viewFilterTimeout = setTimeout(() => {
    dispatch(updateVisibleElements(view));
  }, 400);
  dispatch({
    type: AT.CHANGE_SEARCH_VALUE,
    payload: { view, value },
  });
};

export const updateElementsOrder = (view, sortKey) => (dispatch, getState) => {
  switch (view) {
    case "templates": {
      dispatch(sortTemplates(sortKey));
      break;
    }
    case "reports": {
      dispatch(sortReports(sortKey));
      break;
    }
    default:
      return;
  }
};

export const updateVisibleElements = view => dispatch => {
  switch (view) {
    case "templates": {
      dispatch(calculateVisibleTemplates());
      break;
    }
    case "reports": {
      dispatch(calculateVisibleReports());
      break;
    }
    case "employees": {
      dispatch(calculateVisibleEmployees());
    }
    default:
      return;
  }
};

export const addNextTemplateItem = () => ({ type: AT.ADD_NEXT_TEMPLATE_ITEM });
export const deleteTemplateItem = itemId => ({
  type: AT.DELETE_TEMPLATE_ITEM,
  payload: itemId,
});
export const reorderTemplateItems = order => ({
  type: AT.REORDER_TEMPLATE_ITEM,
  payload: order,
});

export const resetView = viewName => ({
  type: AT.RESET_VIEW_STATE,
  payload: viewName,
});

export const setViewState = (viewName, data) => ({
  type: AT.SET_VIEW_STATE,
  payload: { viewName, data },
});

export const hideModal = () => ({
  type: AT.HIDE_MODAL,
});

export const showModal = (modalName, data) => ({
  type: AT.SHOW_MODAL,
  payload: { modalName, data },
});

export const changeDateRange = (start, end) => ({
  type: AT.CHANGE_DATE_RANGE,
  payload: { start, end },
});

export const changeViewLoadingState = (viewName, isLoading) => ({
  type: AT.CHANGE_VIEW_LOADING_STATUS,
  payload: { view: viewName, isLoading },
});

import { connect } from "react-redux";
import Component from "./EmployeesTable.jsx";
import { deleteEmployee } from "../../../actions/employees";
import { setViewState, showModal } from "../../../actions/uiState";

const mapStateToProps = (state, props) => ({
  employees: state.reducer.employees,
});

const mapDispatchToProps = {
  deleteEmployee,
  showModal,
  setViewState,
};

const AttachEmployeeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default AttachEmployeeContainer;

import classNames from "classnames";

export const processModifiers = (classes, base, modifiers) => {
  if (!modifiers) return classes;
  if (!Array.isArray(modifiers)) {
    modifiers = modifiers.split(" ");
  }
  const className = classNames(
    classes,
    ...modifiers.map(modifier => `${base}--${modifier}`)
  );
  return className;
};

export const createPromiseAction = (func, onSuccess, onError, beforeAction) => (
  ...args
) => (dispatch, getState) =>
  new Promise((resolve, reject) => {
    if (beforeAction) beforeAction(dispatch);
    func(...args)
      .then(result => {
        if (onSuccess) onSuccess(result, args, dispatch, getState);
        resolve(result);
      })
      .catch(err => {
        console.error(
          `Error while running function ${func.name}`,
          err,
          err.response,
          args
        );
        if (onError) onError(err, args, dispatch, getState);
        reject(err);
      });
  });

export const sleep = async time => {
  return new Promise(resolve => {
    setTimeout(resolve, time);
  });
};

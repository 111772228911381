import { connect } from "react-redux";
import Component from "./EmployeesView.jsx";
import { showModal } from "../../actions/uiState";

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  showModal,
};

const AttachEmployeeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default AttachEmployeeContainer;

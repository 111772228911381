import React, {Component} from 'react';
import { Input } from 'antd';

const { TextArea } = Input;

export default class TextStep extends Component {
    render(){
        return ( <>
            <TextArea
                placeholder="..."
                autoSize={{ minRows: 2, maxRows: 20 }}
                onChange={this.props.onChangeText}
                value={this.props.value}
            />
        </>)
    }
}

import { connect } from "react-redux";
import Component from "./NewPasswordView.jsx";
import { changeInputValue } from "../../actions/uiState";
import { saveNewPassword } from "../../actions/passwordReset";

const mapStateToProps = state => ({
  uiState: state.reducer.uiState.newPassword,
});

const mapDispatchToProps = {
  changeInputValue,
  saveNewPassword,
};

const Container = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Container;

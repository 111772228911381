import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {processModifiers} from "../../../utils/misc";
import Icon from "@mdi/react";
import {mdiChevronDown, mdiChevronUp} from "@mdi/js";

import './headerItem.scss';

const HeaderItem = props => {
    const {title, onClick, sortKey, uiState} = props;
    const className = classNames('c-headerItem',props.className,{
        'c-headerItem--clickable':!!sortKey
    });
    const sortUp = uiState.key === sortKey && !uiState.decr;
    const sortDown = uiState.key === sortKey && uiState.decr;
    return (
      <th className={className} onClick={()=>onClick(sortKey)}>
          {title}
          {sortUp?<Icon className="c-headerItem__icon" path={mdiChevronDown} size="16px" />:null}
          {sortDown?<Icon className="c-headerItem__icon" path={mdiChevronUp} size="16px" />:null}
      </th>

    );
};

HeaderItem.defaultProps = {
    type: 'text',
    modifiers:[],
};

HeaderItem.propTypes = {
    className: PropTypes.string,
    modifiers: PropTypes.oneOfType([PropTypes.string, PropTypes.arrayOf(PropTypes.string)]),
    type: PropTypes.string,
    id: PropTypes.string,
    onClick: PropTypes.func,
    disabled: PropTypes.bool,
    children: PropTypes.node,
};

export default HeaderItem;

import * as AT from "../constants/actionTypes";

export const startScoreEdit = stepId => (dispatch, getState) => {
  const { steps, stepsOrder } = getState().reducer.uiState.currentTemplate;
  const stepIndex = stepsOrder.findIndex(s => s === stepId);
  const stepType = steps[stepId].type.value;
  dispatch({
    type: AT.START_SCORE_EDIT,
    payload: {
      stepId,
      rules: steps[stepId].scoreRules,
      stepNo: stepIndex + 1,
      stepType,
    },
  });
};

export const changeScoreRuleValue = (ruleNo, key, value) => ({
  type: AT.CHANGE_SCORE_RULE_VALUE,
  payload: { ruleNo, key, value },
});

export const initNewScoreRule = () => ({ type: AT.INIT_NEW_SCORE_RULE });

export const deleteScoreRule = ruleNo => ({
  type: AT.DELETE_SCORE_RULE,
  payload: ruleNo,
});

export const saveScoreRules = () => (dispatch, getState) => {
  const { rules, stepId } = getState().reducer.uiState.stepScoreModal;
  dispatch({ type: AT.SAVE_SCORE_RULES_FOR_STEP, payload: { rules, stepId } });
};

import React, { Component } from "react";
import { Button, Modal } from "../";
import "./confirmModal.scss";

class ConfirmModal extends Component {
  constructor(props) {
    super(props);
    this.onCancel = this.onCancel.bind(this);
    this.onConfirm = this.onConfirm.bind(this);
  }
  onCancel() {
    const { cancelAction } = this.props.uiState;
    if (cancelAction) cancelAction();
    this.props.hideModal();
  }
  onConfirm() {
    const { confirmAction } = this.props.uiState;
    if (confirmAction) confirmAction();
    this.props.hideModal();
  }
  render() {
    if (!this.props.show) return null;
    const { title, cancelTitle, confirmTitle, content } = this.props.uiState;
    return (
      <Modal name="CONFIRM_MODAL">
        <Modal.Header closeButton>{title}</Modal.Header>
        <Modal.Body>
          <div className="c-confirmModal__content">{content}</div>
        </Modal.Body>

        <div className="c-panel c-panel--autoRows">
          <Button modifiers={["purpleReverse"]} onClick={this.onCancel}>
            {cancelTitle || "Anuluj"}
          </Button>
          <Button modifiers={["purple"]} onClick={this.onConfirm}>
            {confirmTitle || "Potwierdź"}
          </Button>
        </div>
      </Modal>
    );
  }
}

export default ConfirmModal;

import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
// import Select from "react-select";
import { Select } from "antd";
import "react-select/dist/react-select.css";
import { processModifiers } from "../../../utils/misc";
import "./multiselectInput.scss";

const MultiselectInput = props => {
  const { modifiers, options, value, id, onChange, label } = props;
  console.log("V=>", value);
  const disabled = props.disabled || modifiers.includes("disabled");
  const baseClassName = classNames("c-button", props.className, {
    "c-button--disabled": disabled,
  });
  const className = processModifiers(baseClassName, "c-button", modifiers);
  const selected = value || [];
  const selectedIds = selected.map(i => i.value);
  return (
    <div className="c-selectInput">
      <span className="c-selectInput__label">{label}</span>
      <Select
        multi
        mode={"multiple"}
        options={options}
        value={selectedIds}
        onChange={values => {
          console.log("V=>", value);
          onChange({
            target: {
              id,
              value: values.map(v => ({ value: v })),
              type: "select",
            },
          });
        }}
      />
    </div>
  );
};

MultiselectInput.defaultProps = {
  type: "button",
  modifiers: [],
};

MultiselectInput.propTypes = {
  className: PropTypes.string,
  modifiers: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string),
  ]),
  type: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node,
};

export default MultiselectInput;

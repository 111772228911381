import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { v4 } from "uuid";
import { processModifiers } from "../../../utils/misc";

import "./toggle.scss";

const Toggle = props => {
  const { modifiers, label, checked, onChange, id } = props;
  const toggleID = v4();
  const baseClassName = classNames("c-toggle", props.className);
  const className = processModifiers(baseClassName, "c-toggle", modifiers);
  return (
    <div className={className}>
      <div className="c-toggle__label">{label}</div>
      <div className="c-toggle__container">
        <label htmlFor={`toggle-${toggleID}`}>
          <input
            name={id}
            type="checkbox"
            id={`toggle-${toggleID}`}
            checked={checked}
            onChange={onChange}
          />
          <span className="c-toggle__switch" />
          <span className="c-toggle__toggle" />
        </label>
      </div>
    </div>
  );
};

Toggle.defaultProps = {
  modifiers: [],
};

Toggle.propTypes = {};

export default Toggle;

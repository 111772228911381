import * as AT from "../../constants/actionTypes";

const emptyRule = { step: null, rule: null, value: null };
const initialState = {
  stepId: null,
  rules: [{ ...emptyRule }],
};

const reducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case AT.START_RULES_EDIT: {
      let rules = action.payload.rules;
      if (!rules || !rules.length) rules = [{ ...emptyRule }];
      return {
        ...state,
        stepId: action.payload.stepId,
        rules,
        stepNo: action.payload.stepNo,
      };
    }
    case AT.CHANGE_RULE_VALUE: {
      const { ruleNo, key, value } = action.payload;
      return {
        ...state,
        rules: state.rules.map((r, i) => {
          if (i !== ruleNo) return r;
          return { ...r, [key]: value };
        }),
      };
    }
    case AT.INIT_NEW_RULE: {
      return { ...state, rules: [...state.rules, { ...emptyRule }] };
    }
    case AT.DELETE_RULE: {
      return {
        ...state,
        rules: state.rules.filter((r, i) => i !== action.payload),
      };
    }
    case AT.RESET_VIEW_STATE: {
      if (action.payload === "stepRulesModal") return { ...initialState };
      return state;
    }
    default:
      return state;
  }
};

export default reducer;

import React from "react";
import cn from "classnames";
import {
  TextStep,
  RatingStep,
  BooleanStep,
  NumberStep,
  PictureStep,
} from "../../common/reportQuestions";
import { Divider, Tooltip } from "antd";
import "./ReportViewQuestion.scss";
import { changeAnswerValue } from "../../../actions/ReportView";
import Icon from "@mdi/react";
import { mdiDebugStepOver, mdiCircleEditOutline } from "@mdi/js";
import * as Icons from "@mdi/js";

const getInputForStepType = (type, stepId, value, onChange) => {
  switch (type) {
    case "text":
      return (
        <TextStep
          value={value}
          onChangeText={v => {
            onChange(stepId, v.target.value);
          }}
        />
      );
    case "rating":
      return <RatingStep value={value} onChange={v => onChange(stepId, v)} />;
    case "bool":
      return <BooleanStep onChange={v => onChange(stepId, v)} value={value} />;
    case "number":
      return (
        <NumberStep onChangeText={v => onChange(stepId, v)} value={value} />
      );
    case "photo":
      return <PictureStep onChange={v => onChange(stepId, v)} value={value} />;
    case "sectionName":
      return <Divider />;
    default:
      return null;
  }
};

const ReportViewQuestion = ({
  step,
  onAnswerChange,
  value,
  stepId,
  index,
  isMissingAnswer,
  skipped,
  skipStep,
}) => {
  const { name, comment, required } = step;
  const isSectionName = step.type.value === "sectioName";
  const containerClass = cn("c-reportQuestion__container", {
    "c-reportQuestion--skipped": skipped && !isSectionName,
  });
  const showSkipButton =
    ["rating", "bool"].includes(step.type.value) && !required;
  let skipIcon, skipTooltipText;
  if (skipped) {
    skipIcon = mdiCircleEditOutline;
    skipTooltipText = "Dodaj odpowiedź";
  } else {
    skipIcon = mdiDebugStepOver;
    skipTooltipText = "Pomiń pytanie";
  }
  return (
    <div className={containerClass}>
      <div className="c-reportQuestion__title">
        {`${index}. ${name}`}
        {required ? (
          <span className="c-reportQuestion__requiredStar">*</span>
        ) : null}
        {showSkipButton ? (
          <Tooltip title={skipTooltipText} mouseEnterDelay={0.2}>
            <Icon
              onClick={() => skipStep(stepId)}
              className="c-reportQuestion__skipButton"
              path={skipIcon}
              size="16px"
            />
          </Tooltip>
        ) : null}
      </div>

      {comment ? (
        <div className="c-reportQuestion__comment">{comment}</div>
      ) : null}
      {isMissingAnswer ? (
        <span className="c-reportQuestion__errorMessage">
          Odpowiedź na to pytanie jest wymagana
        </span>
      ) : null}
      <div className="c-reportQuestion__inputContainer">
        {getInputForStepType(step.type.value, stepId, value, onAnswerChange)}
      </div>
    </div>
  );
};

export default ReportViewQuestion;

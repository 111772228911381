import { combineReducers } from "redux";

import * as AT from "../constants/actionTypes.js";

import uiState from "./uiState/";
import auth from "./auth";
import availableTemplates from "./availableTemplates";
import templates from "./templates";
import reports from "./reports";
import employees from "./employees";
import dateRange from "./dateRage";
import nav from "./nav";

const reducer = combineReducers({
  auth,
  availableTemplates,
  dateRange,
  employees,
  nav,
  templates,
  reports,
  uiState,
});

const rootReducer = (state, action) => {
  if (action.type === AT.AUTH_USER_LOGOUT) {
    state = undefined;
  }
  return reducer(state, action);
};
export default rootReducer;

import { connect } from "react-redux";
import Component from "./AvailableTemplatesTable.jsx";
import { setViewState, showModal } from "../../../actions/uiState";
import {
  deleteTemplate,
  saveCurrentTemplate,
  startTemplateEdit,
} from "../../../actions/templates";
import {startNewReportCreation} from "../../../actions/newReport";

const mapStateToProps = (state, props) => ({
  availableTemplates: state.reducer.availableTemplates,
  employees: state.reducer.employees,
});

const mapDispatchToProps = {
  saveCurrentTemplate,
  startTemplateEdit,
  deleteTemplate,
  showModal,
  startNewReportCreation,
};

const AttachEmployeeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default AttachEmployeeContainer;

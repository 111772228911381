import React from "react";
import PropTypes from "prop-types";
import moment from "moment";
import "react-date-range/dist/styles.css"; // main style file
import "react-date-range/dist/theme/default.css"; // theme css file
import "./dateRangeInput.scss";
import Icon from "@mdi/react";
import { mdiCalendarText } from "@mdi/js";

import { DateRangePicker } from "react-date-range";
import { pl } from "react-date-range/src/locale/index.js";
import { defaultStaticRanges, defaultInputRanges } from "react-date-range/src/defaultRanges.js";

class DateRangeInput extends React.Component {
  constructor(props) {
    super(props);
    const { start, end } = props.dateRange;
    this.state = { showCalendar: false, start, end };
    this.onButtonClick = this.onButtonClick.bind(this);
    this.hidePopup = this.hidePopup.bind(this);
    this.clickListener = this.clickListener.bind(this);
    this.handleSelect = this.handleSelect.bind(this);

    this.startedSelection = false;
  }

  componentWillReceiveProps(nextProps) {
    const { start, end } = nextProps.dateRange;
    if (this.state.start !== start || this.state.end !== end) {
      this.setState({ start, end });
    }
  }

  handleSelect(ranges) {
    const { startDate, endDate } = ranges.selection;
    let startMoment = moment(startDate);
    let endMoment = moment(endDate);
    let diff = Math.abs(endMoment.diff(startMoment, "days"));
    if (diff === 0 && !ranges.selection.button) {
      if (this.startedSelection) {
        this.startedSelection = false;
        this.props.changeDateRange(
          startMoment.format("YYYY.MM.DD"),
          endMoment.format("YYYY.MM.DD")
        );
      } else {
        this.startedSelection = true;
        this.setState({
          start: startMoment.format("YYYY.MM.DD"),
          end: startMoment.format("YYYY.MM.DD"),
        });
      }
    } else {
      this.startedSelection = false;
      this.props.changeDateRange(
        startMoment.format("YYYY.MM.DD"),
        endMoment.format("YYYY.MM.DD")
      );
    }
  }

  clickListener(e) {
    if (this.popup && !this.popup.contains(e.target)) {
      this.hidePopup();
    }
  }
  onButtonClick() {
    if (!this.state.showCalendar) {
      this.setState({ showCalendar: true }, () => {
        document.addEventListener("click", this.clickListener);
      });
    }
  }

  hidePopup() {
    this.setState({ showCalendar: false });
    document.removeEventListener("click", this.clickListener);
  }

  render() {
    const { start, end } = this.state;
    const { showCalendar } = this.state;
    const selectionRange = {
      startDate: new Date(start),
      endDate: new Date(end),
      key: "selection",
    };
    const staticRangesTranslations = {
      Today: "Dzisiaj",
      Yesterday: "Wczoraj",
      "This Week": "Ten tydzień",
      "Last Week": "Poprzedni tydzień",
      "This Month": "Ten miesiąc",
      "Last Month": "Poprzedni miesiąc",
    };
    let staticRanges = defaultStaticRanges.map(range => ({
      ...range,
      label: staticRangesTranslations[range.label],
      range: () => {
        const standard = range.range();
        return {
          ...standard,
          button: true,
        };
      },
    }));
    let inputRanges = [...defaultInputRanges];
    inputRanges[0].label = "dni wstecz";
    inputRanges[1].label = "dni w przód";
    let showPopup = false;

    let dateText = `${moment(start).format("DD.MM.YYYY")}-${moment(end).format(
      "DD.MM.YYYY"
    )}`;
    if (start === end) {
      dateText = `${moment(start).format("DD.MM.YYYY")}`;
    }

    return (
      <div className="c-dataRange">
        <div className="c-dataRange__button" onClick={this.onButtonClick}>
          <Icon
            className="c-dataRange__icon"
            path={mdiCalendarText}
            size="18px"
          />
          <span>{dateText}</span>
        </div>
        {showCalendar ? (
          <div
            className="c-dataRange__popup"
            ref={ref => {
              this.popup = ref;
            }}
          >
            <DateRangePicker
              ranges={[selectionRange]}
              onChange={this.handleSelect}
              months={2}
              direction={"horizontal"}
              locale={pl}
              staticRanges={staticRanges}
              inputRanges={[]}
              monthDisplayFormat={"MMMM YYYY"}
            />
          </div>
        ) : null}
      </div>
    );
  }
}

DateRangeInput.propTypes = {};

export default DateRangeInput;

import { createStore, applyMiddleware, compose, combineReducers } from "redux";
import thunkMiddleware from "redux-thunk";
import { intlReducer } from "./utils/reactIntlRedux";
import { connectRouter, routerMiddleware } from "connected-react-router";
import { createBrowserHistory } from "history";
import { dateChangeMiddleware } from "./actions/viewData";

import reducer from "./reducers";
import { getInitialIntl } from "./utils/intlHelpers.jsx";

/* eslint-disable no-underscore-dangle */

const composeEnhancers =
  process.env.NODE_ENV === "development" &&
  window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__
    ? window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__({
        actionsBlacklist: ["_LOADER_COUNTER"]
      })
    : compose;
/* eslint-enable */

export const history = createBrowserHistory();

const createReducer = history =>
  combineReducers({
    reducer,
    intl: intlReducer,
    router: connectRouter(history)
  });

const store = createStore(
  createReducer(history),
  getInitialIntl(),
  composeEnhancers(
    applyMiddleware(
      thunkMiddleware.withExtraArgument(getInitialIntl().intl),
      dateChangeMiddleware,
      routerMiddleware(history)
    )
  )
);

export default store;

import * as AT from "../../constants/actionTypes";

const emptyRule = stepType => ({
  ruleType: "equals",
  ruleValue: stepType === "bool" ? true : 1,
  actionType: "add",
  actionValue: 1,
});

const initialState = {
  stepId: null,
  rules: [],
};

const reducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case AT.START_SCORE_EDIT: {
      let rules = action.payload.rules;
      if (!rules || !rules.length)
        rules = [{ ...emptyRule(action.payload.stepType) }];
      return {
        ...state,
        stepId: action.payload.stepId,
        rules,
        stepNo: action.payload.stepNo,
        stepType: action.payload.stepType,
      };
    }
    case AT.CHANGE_SCORE_RULE_VALUE: {
      const { ruleNo, key, value } = action.payload;
      return {
        ...state,
        rules: state.rules.map((r, i) => {
          if (i !== ruleNo) return r;
          return { ...r, [key]: value };
        }),
      };
    }
    case AT.INIT_NEW_SCORE_RULE: {
      return {
        ...state,
        rules: [...state.rules, { ...emptyRule(state.stepType) }],
      };
    }
    case AT.DELETE_SCORE_RULE: {
      return {
        ...state,
        rules: state.rules.filter((r, i) => i !== action.payload),
      };
    }
    case AT.RESET_VIEW_STATE: {
      if (action.payload === "stepScore") return { ...initialState };
      return state;
    }
    default:
      return state;
  }
};

export default reducer;

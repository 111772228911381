import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Radio, InputNumber, Input, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import './scoreNamesInput.scss';

const ScoreNamesInput = props => {
  const {value, label,id, onChange} = props;
  const {names, type} = value;
  const onValueChange = (key,value,index)=>{
    const newNames = [...names]
    if(!newNames[index]) newNames[index] = {value:0, name:''};
    if(key==='value'){
      newNames[index][key] = value;
    }else{
      newNames[index][key] = value.target.value;
    }

    props.onChange({target:{value:{...props.value, names:newNames}, id:props.id}})
  }

  const addNewItem = ()=>{
    props.onChange({target:{id: props.id, value:{...props.value, names: [...props.value.names, {value:0, name:''}]}}})
  }

  return (
    <div className={'c-scoreNamesInput'}>
      <div className="c-textInput__label">{label}</div>
      <Radio.Group value={type} onChange={(v)=>{
        console.log(v)}} className={'c-scoreNamesInput__radio'}>
        <Radio.Button value="percents">Procenty</Radio.Button>
        <Radio.Button value="points">Punkty</Radio.Button>
      </Radio.Group>
      {names.map((scoreName,i)=>
        <Input.Group compact  className="c-scoreNamesInput__inputRow">
          <InputNumber  value={scoreName.value} className="c-scoreNamesInput__scoreInput" onChange={value=>onValueChange('value',value, i)}/>
          <Input  value={scoreName.name} className="c-scoreNamesInput__nameInput"  onChange={value=>onValueChange('name',value, i)} />
        </Input.Group>
      )}
    <br/>
      <Button type="primary" onClick={addNewItem} icon={<PlusOutlined />} >Dodaj próg punktowy</Button>
    </div>
  );
};

ScoreNamesInput.defaultProps = {
  type: 'button',
  modifiers: [],
};

ScoreNamesInput.propTypes = {

};

export default ScoreNamesInput;

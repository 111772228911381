import React from "react";
import ReportView from "../reportView/ReportView.jsx";

const CreateReportView = props => {
  return (
    <ReportView
      {...props}
      returnPath={"/fillReport"}
      onSubmit={props.submitReport}
    />
  );
};

export default CreateReportView;

import * as AT from '../constants/actionTypes';

const formatTemplate = t => {
  const id = t._id;
  const stepsOrder = [];
  const steps = {};
  t.steps.forEach((s, i) => {
    const index = `i-${i + 1}`;
    stepsOrder.push(index);
    steps[index] = { ...s };
  });
  const template = { ...t, steps, stepsOrder };
  return { template, id };
};

const initialState = {
  data: {},
  order: [],
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AT.GET_AVA_TEMPLATES_SUCCESS: {
      const data = {};
      const order = [];
      action.payload.forEach(t => {
        const { template, id } = formatTemplate(t);
        data[id] = template;
        order.push(id);
      });
      return { ...state, data, order };
    }
    case AT.CHANGE_VIEW_LOADING_STATUS: {
      if (action.payload.view === "fillReport") {
        return { ...state, loading: action.payload.isLoading };
      }
      return state;
    }
    default:
      return state;
  }
};

export default reducer;

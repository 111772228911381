import React, { Component } from "react";
import { Row, Col, PageHeader } from "antd";
import { SolutionOutlined } from "@ant-design/icons";

import ReportsTable from "./reportsTable/ReportsTable.redux";
import { SearchBox, DateRangeInput } from "../common/";

class ReportsView extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <React.Fragment>
        <Row>
          <Col span={24}>
            <PageHeader
              title={
                <span>
                  <SolutionOutlined style={{ marginRight: "10px" }} />
                  <span>Raporty</span>
                </span>
              }
              extra={[<DateRangeInput />, <SearchBox viewName="reports" />]}
            />
          </Col>
        </Row>
        <Row style={{ padding: "0 20px" }}>
          <Col span={24}>
            <ReportsTable />
          </Col>
        </Row>
      </React.Fragment>
    );
  }
}

export default ReportsView;

import React, { Component } from 'react';
import { PropTypes } from 'prop-types';

class ModalBody extends Component {
  constructor(props) {
    super(props);
    this.onWheel = this.onWheel.bind(this);
  }
  componentDidMount() {
    if (this.props.eneableScroll) this.modalBody.addEventListener('wheel', this.onWheel);
  }
  componentWillUnmount() {
    if (this.props.eneableScroll) this.modalBody.removeEventListener('wheel', this.onWheel);
  }
  onWheel(e) {
    if (
      (this.modalBody.scrollTop + this.modalBody.offsetHeight === this.modalBody.scrollHeight && e.deltaY > 0) ||
      (this.modalBody.scrollTop === 0 && e.deltaY < 0)
    ) {
      e.preventDefault();
    }
  }
  render() {
    return (
      <div
        className="kmodal__body"
        ref={ref => {
          this.modalBody = ref;
        }}
      >
        {this.props.children}
      </div>
    );
  }
}

ModalBody.propTypes = {
  children: PropTypes.node,
};
export default ModalBody;

import React, { Component } from "react";
import { Button, Table, ConfigProvider, Popconfirm, Empty } from "antd";
import moment from "moment";
import {
  DeleteTwoTone,
  EditTwoTone,
  FilePdfTwoTone,
  WarningTwoTone,
} from "@ant-design/icons";

class ReportsTable extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const {
      reports,
      templates,
      employees,
      getReportPdf,
      deleteReport,
      editReport,
    } = this.props;
    const { order, visible, data, loading } = reports;

    const processedReports = order
      .filter(id => visible.includes(id))
      .map(id => {
        let report = data[id];
        let template = templates.data[report.template_id];
        report.templateName = template ? template.info.name : "";
        let author = employees.data[report.author];
        report.locationName = author
          ? `${author.first_name} ${author.last_name}`
          : "";
        return report;
      });

    return (
      <ConfigProvider
        renderEmpty={() => {
          if (loading) return <div className="loadingEmptySpace" />;
          return <Empty description={<p>Brak raportów do wyświetlenia</p>} />;
        }}
      >
        <Table
          bordered
          loading={loading}
          size={"small"}
          pagination={{
            pageSize: 20,
            hideOnSinglePage: true,
            pageSizeOptions: ["5", "20", "50", "100", "200"],
            showSizeChanger: true,
            locale: { items_per_page: "" },
            showTotal: (total, range) => {
              return `${range[0]} - ${range[1]} z ${total} raportów`;
            },
          }}
          dataSource={processedReports}
          columns={[
            {
              title: "Nazwa szablonu",
              dataIndex: "templateName",
              align: "center",
              sorter: (a, b) => a.templateName.localeCompare(b.templateName),
            },
            {
              title: "Data dodania",
              dataIndex: "created_at",
              align: "center",
              sorter: (a, b) => (a.created_at > b.created_at ? 1 : -1),
              defaultSortOrder: "descend",
              render: item => (
                <span>{moment(item).format("DD MMMM YYYYr. HH:mm")}</span>
              ),
            },
            {
              title: "Lokalizacja",
              dataIndex: "locationName",
              align: "center",
              sorter: (a, b) => a.locationName.localeCompare(b.locationName),
            },
            {
              title: "Opcje",
              align: "center",

              render: report => (
                <React.Fragment>
                  <Button
                    icon={<EditTwoTone />}
                    type={"link"}
                    onClick={() => {
                      editReport(report._id);
                    }}
                  >
                    Edytuj
                  </Button>
                  <Button
                    icon={<FilePdfTwoTone />}
                    type={"link"}
                    onClick={() => {
                      getReportPdf(report._id);
                    }}
                  >
                    Pobierz
                  </Button>
                  <Popconfirm
                    title={
                      <span>
                        Czy na pewno chcesz usunąć ten raport?
                        <br /> Operacja jest nieodwracalna!
                      </span>
                    }
                    onConfirm={() => {
                      deleteReport(report._id);
                    }}
                    onCancel={() => {}}
                    okText="Usuń"
                    cancelText="Anuluj"
                    icon={<WarningTwoTone />}
                  >
                    <Button icon={<DeleteTwoTone />} type={"link"}>
                      Usuń
                    </Button>
                  </Popconfirm>
                </React.Fragment>
              ),
            },
          ]}
        />
      </ConfigProvider>
    );
  }
}

export default ReportsTable;

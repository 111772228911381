import React, { Component } from "react";
import Provider from "./utils/reactIntlRedux/Provider";
import { ReactReduxContext } from "react-redux";
import { ConnectedRouter } from "connected-react-router";
import moment from "moment";
import momentLocale from "moment/locale/pl";
import * as Sentry from "@sentry/react";
import { Integrations } from "@sentry/tracing";
import { getCookie } from "./utils/cookieHelpers";
import { authenticationSuccess, reLogin } from "./actions/auth";
import ConfirmModal from "./components/common/confirmModal/ConfirmModal.redux";

import store, { history } from "./store.js";
import Routes from "./components/routes/routes.redux";

import "antd/dist/antd.css";
import "./styles/main.scss";
import "./styles/antdOverride.scss";

moment.locale("pl", momentLocale);

class App extends Component {
  componentWillMount() {
    Sentry.init({
      dsn:
        "https://3fe2dadd2fa74513a26b127d366666b2@o298193.ingest.sentry.io/5643064",
      integrations: [new Integrations.BrowserTracing()],
      tracesSampleRate: 1.0
    });

    const token = getCookie("authToken");
    if (token) store.dispatch(reLogin(token));
  }

  render() {
    return (
      <Provider store={store} context={ReactReduxContext}>
        <ConnectedRouter history={history} context={ReactReduxContext}>
          <div className="App">
            <ConfirmModal />
            <Routes />
          </div>
        </ConnectedRouter>
      </Provider>
    );
  }
}

export default App;

import { setViewState } from "./uiState";
import { validate } from "../utils/validation";
import { registerAccount } from "../api/register";
import { sleep } from "../utils/misc";
import { login } from "./auth";

const startRegisterViewLoader = () => dispatch => {
  dispatch(setViewState("register", { loading: true }));
};

const stopRegisterViewLoading = () => dispatch => {
  dispatch(setViewState("register", { loading: false }));
};

export const registerValidationFields = allFields => ({
  firstName: {
    name: "firstName",
    key: "firstName",
    options: { required: true },
  },
  lastName: { name: "lastName", key: "lastName", options: { required: true } },
  companyName: {
    name: "companyName",
    key: "companyName",
    options: { required: true },
  },
  phone: { name: "phone", key: "phone", options: { required: true } },
  email: { name: "email", key: "email", options: { required: true } },
  password: { name: "password", key: "password", options: { required: true } },
  confirmPassword: {
    name: "confirmPassword",
    key: "confirmPassword",
    options: { required: true, valueToCompare: allFields.password },
  },
  accessCode: {
    name: "accessCode",
    key: "accessCode",
    options: { required: true },
  },
});

export const validateRegisterObject = registerObject => dispatch => {
  const fields = Object.values(registerValidationFields(registerObject));
  const errors = {};
  fields.forEach(field => {
    const error = validate(
      field.name,
      registerObject[field.key],
      field.options
    );
    if (error) errors[field.key] = error;
  });
  dispatch(setViewState("register", { errors }));
  return !Object.values(errors).length;
};

export const registerNewAccount = () => (dispatch, getState) => {
  return new Promise((resolve, reject) => {
    const { register } = getState().reducer.uiState;
    console.log("REG", register);
    const isObjectValid = dispatch(validateRegisterObject(register));
    console.log("valid", isObjectValid);
    if (!isObjectValid) {
      reject();
      return;
    }
    const {
      firstName,
      lastName,
      password,
      phone,
      accessCode,
      companyName,
      email,
    } = register;
    dispatch(startRegisterViewLoader());
    registerAccount({
      first_name: firstName,
      last_name: lastName,
      password,
      phone,
      email,
      company_name: companyName,
      access_code: accessCode,
    }).then(result => {
      console.log("result", result);
      dispatch(login({ email, password })).then(loginResult => {
        console.log("LR", loginResult);
        dispatch(stopRegisterViewLoading());
      });
    });
  });
};

export const validateProp = (key, value, registerObject) => {
  const field = registerValidationFields(registerObject)[key];
  return validate(field.name, value, field.options);
};

import { Upload, Modal } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import React, { Component } from "react";
import { sendImage } from "../../api/reports";

function getBase64(file) {
  return new Promise((resolve, reject) => {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = () => resolve(reader.result);
    reader.onerror = error => reject(error);
  });
}

class PicturesWall extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      previewVisible: false,
      previewImage: "",
      previewTitle: "",
      fileList: [],
    };

    this.pictureData = [];

    this.onRemove = this.onRemove.bind(this);
  }

  componentDidMount() {
    if (this.props.value) {
      const pictures = this.props.value.map(item => ({
        uid: item.id,
        thumbUrl: item.url,
        url: item.url,
      }));
      this.setState({ fileList: pictures });
      this.pictureData = this.props.value;
    }
  }

  componentW;

  handleCancel = () => this.setState({ previewVisible: false });

  handlePreview = async file => {
    if (!file.url && !file.preview) {
      file.preview = await getBase64(file.originFileObj);
    }

    this.setState({
      previewImage: file.url || file.preview,
      previewVisible: true,
      previewTitle:
        file.name || file.url.substring(file.url.lastIndexOf("/") + 1),
    });
  };

  handleChange = change => {
    console.log("Change", change);
    const { fileList } = change;
    console.log("FL", fileList);
    this.setState({ fileList });
    if (this.props.onChange) this.props.onChange(this.pictureData);
  };

  onRemove(picture) {
    console.log("removed", picture);
    const pictureId = picture.response
      ? picture.response.data._id
      : picture.uid;
    this.pictureData = this.pictureData.filter(item => item.id !== pictureId);
    if (this.props.onChange) this.props.onChange(this.pictureData);
    if (picture.response) {
      console.log("ID", picture.response.data._id);
    } else {
      console.log("ID", picture.uid);
    }
  }

  render() {
    const { previewVisible, previewImage, fileList, previewTitle } = this.state;

    const uploadButton = (
      <div>
        <PlusOutlined />
        <div style={{ marginTop: 8 }}>Upload</div>
      </div>
    );
    return (
      <>
        <Upload
          action={async value => {
            console.log("V", value);
            return "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png";
          }}
          onRemove={this.onRemove}
          customRequest={async v => {
            console.log("v", v);
            const result = await sendImage(v.file).catch(v.onError);
            console.log("Res", result);
            this.pictureData.push({
              id: result.data._id,
              url: result.data.url,
            });
            v.onSuccess(result);

            return "https://zos.alipayobjects.com/rmsportal/jkjgkEfvpUPVyRjUImniVslZfWPnJuuZ.png";
          }}
          listType="picture-card"
          fileList={fileList}
          onPreview={this.handlePreview}
          onChange={this.handleChange}
          multiple
        >
          {fileList.length >= 100 ? null : uploadButton}
        </Upload>
        <Modal
          visible={previewVisible}
          title={previewTitle}
          footer={null}
          onCancel={this.handleCancel}
        >
          <img alt="example" style={{ width: "100%" }} src={previewImage} />
        </Modal>
      </>
    );
  }
}
export default PicturesWall;

import React, { Component } from "react";
import { BrowserRouter as Router, Route, Switch } from "react-router-dom";
import { withRouter } from "react-router-dom";
import Info from "../info/Info.jsx";
import { history } from "../../store";
import UnauthApp from "../unauthApp/UnauthApp.redux";
import AuthApp from "../authApp/AuthApp.redux";

const formatPath = path => path.replace(/\/$/, "");

class Routes extends Component {
  componentDidMount() {
    this.props.loadDataForView(history.location.pathname.replace(/\/$/, ""));
    this.unlisten = history.listen(l => {
      const path = formatPath(l.pathname);
      this.props.loadDataForView(path, this.lastPath);
      this.lastPath = path;
    });
  }

  componentWillUnmount() {
    this.unlisten();
  }
  render() {
    const { authenticated } = this.props;
    // Authenticated part of the app
    if (authenticated) {
      return (
        <div>
          <Route path="/" component={AuthApp} />
        </div>
      );
    }

    // Unauthenticated part of the app
    return (
      <div>
        <Route path="/" component={UnauthApp} />
      </div>
    );
  }
}

// Had to do this to get access to history on this lvl.
// const R = withRouter(Routes);
// const WrappedRoutes = props => {
//   return <R {...props} />;
// };

export default Routes;

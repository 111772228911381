// import * as Sentry from '@sentry/react-native';
import * as AT from "../constants/actionTypes";
import * as Reports from "../api/reports";
//
import { sendAllImagesForReport } from "./images";
import { createPromiseAction } from "../utils/misc";

export const sendImage = createPromiseAction(Reports.sendImage);

export const startNewReportCreation = templateId => (dispatch, getState) => {
  const { availableTemplates: templates } = getState().reducer;
  const template = templates.data[templateId];
  const { steps, stepsOrder } = template;

  const hiddenStepsIdMap = stepsOrder.reduce((sum, stepId) => {
    if (
      steps[stepId] &&
      steps[stepId].displayRules &&
      steps[stepId].displayRules.length
    )
      return { ...sum, [stepId]: true };
    return sum;
  }, {});
  const firstStep = template.steps[template.stepsOrder[0]];
  dispatch({
    type: AT.START_REPORT_CREATION,
    payload: {
      templateId,
      template,
      firstStepType: firstStep.type.value,
      hiddenSteps: hiddenStepsIdMap,
    },
  });
};
export const checkStepRules = (rules, steps, authorStep) => {
  if (!rules || !rules.length) {
    return true;
  }
  let shift = 0;
  if (authorStep) {
    shift = 1;
  }
  for (let i = 0; i < rules.length; i++) {
    try {
      const rule = rules[i];
      const stepNo = parseInt(rule.step) + shift;
      let ruleRule = rule.rule;
      if (ruleRule === "=") {
        ruleRule = "==";
      }
      console.log("SN", stepNo);
      let stepValue = steps[stepNo] ? steps[stepNo].value : null;
      if (typeof stepValue === "string") {
        stepValue = `"${stepValue}"`;
      }
      const checkString = `${stepValue} ${ruleRule} ${rule.value}`;
      console.log("Test", typeof stepValue);
      console.log("Check", checkString);
      console.log("Checking rule=>", rule, checkString, eval(checkString));
      if (!eval(checkString)) {
        return false;
      }
    } catch (err) {
      console.log("ERRor", err);
    }
  }
  return true;
};
export const newReportGoToStep = (
  stepNumber,
  { skipCurrent = false },
  direction = null
) => (dispatch, getState) => {
  const number = stepNumber;
  const { availableTemplates: templates, uiState: ui } = getState().reducer;
  const template = templates.data[ui.newReport.templateId];
  const step = template.steps[template.stepsOrder[stepNumber - 1]];
  const stepType = step.type.value;
  const { steps, currentStep, currentStepData } = ui.newReport;
  const tmpSteps = { ...steps };
  let moveDirection = stepNumber > currentStep ? 1 : -1;
  if (direction) {
    moveDirection = direction;
  }
  tmpSteps[currentStep] = currentStepData;
  const { displayRules } = step;
  const rulesMatch = checkStepRules(
    displayRules,
    tmpSteps,
    template.info.requireAuthorName
  );
  dispatch({
    type: AT.NEW_REPORT_GO_TO_STEP,
    payload: { stepNumber: number, stepType, skipCurrent },
  });

  if (!rulesMatch && !(number + moveDirection < 0)) {
    if (number + moveDirection > template.stepsOrder.length) {
      dispatch(submitNewReport());
      dispatch(sendReport());
    } else {
      dispatch(
        newReportGoToStep(
          number + moveDirection,
          { skipCurrent: true },
          moveDirection
        )
      );
    }
  }
};
//
export const submitNewReport = () => ({
  type: AT.SUBMIT_NEW_REPORT,
});

export const updateSendingStatus = payload => ({
  type: AT.UPDATE_SENDING_STATUS,
  payload,
});

export const restartReportSending = () => dispatch => {
  dispatch(
    updateSendingStatus({
      sending: true,
      message: "Wysyłanie raportu...",
      error: false,
    })
  );
  dispatch(sendReport());
};
const sendReportData = reportData => {
  return;
};

export const addPhoto = (view, event, uid = null) => dispatch => {
  const { name, value } = event;
  dispatch({
    type: AT.ADD_PHOTO,
    payload: { view, name, value, uid },
  });
};

export const sendReport = () => (dispatch, getState) => {
  return new Promise(async (resolve, reject) => {
    const { uiState: ui, availableTemplates: templates } = getState().reducer;
    const newReport = { ...ui.newReport };

    dispatch(
      updateSendingStatus({ sending: true, message: "Wysyłanie raportu..." })
    );

    const template = templates.data[newReport.templateId];
    let author = "";
    if (template.info.requireAuthorName) {
      newReport.steps = { ...newReport.steps };
      author = newReport.steps["1"].value;
      delete newReport.steps["1"];
    }
    let reportWithImages;
    try {
      reportWithImages = newReport;
      dispatch(updateSendingStatus({ message: "Finalizacja", progress: 90 }));
      console.log("VALUES", steps);
      const { templateId, steps } = reportWithImages;
      const reportData = {
        template_id: templateId,
        info: { ...newReport.info, author },
        values: Object.values(steps).map(item => {
          if (!Array.isArray(item.value)) return item;
          const newItem = { ...item };
          newItem.value = newItem.value.map(photo => ({
            ...photo,
            url: undefined,
          }));
          return newItem;
        }),
      }; // TODO: ensure values order

      const result = await Reports.sendReport(reportData);
      dispatch(
        updateSendingStatus({
          message: "Raport został wysłany!",
          progress: 100,
          sending: false,
        })
      );
      dispatch({
        type: "SET_CURRENT_ROUTE",
        payload: { route: "/fillReport" },
      });
      dispatch({ type: AT.RESET_VIEW_STATE, payload: "newReport" });

      // Sentry.captureEvent({
      //   category: 'reports',
      //   message: 'Report has been sent',
      //   level: Sentry.Severity.Info,
      //   tags: {
      //     template_id: template._id,
      //     name: template.info.name,
      //     number_of_steps: Object.keys(steps).length,
      //   },
      // });
      resolve();
    } catch (err) {
      // Sentry.captureEvent({
      //   category: 'reports',
      //   message: 'Could not send report',
      //   level: Sentry.Severity.Error,
      //   tags: {
      //     template_id: template._id,
      //     name: template.info.name,
      //     number_of_steps: Object.keys(template.steps).length,
      //   },
      // });
      console.log("Sentry sent", err);
      dispatch(
        updateSendingStatus({
          error: true,
          message: "",
          errorInfo: "",
        })
      );
      reject(err);
    }
  });
};

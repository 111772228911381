import { connect } from "react-redux";
import Component from "./StepRules.jsx";
import { hideModal, showModal } from "../../../actions/uiState";
import { startRulesEdit } from "../../../actions/templates";

const mapStateToProps = state => ({});

const mapDispatchToProps = {
  showModal,
  hideModal,
  startRulesEdit
};

const AttachEmployeeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

export default AttachEmployeeContainer;

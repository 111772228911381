import { push } from "connected-react-router";
import { notification } from "antd";
import * as AT from "../constants/actionTypes";
import * as Reports from "../api/reports";
import { getAuthorQuestion } from "../constants/authorQuestion";

const defaultAnswers = {
  bool: true,
  rating: 3,
};

export const toggleStep = stepId => ({
  type: AT.REPORT_VIEW_SKIP_STEP_TOGGLE,
  payload: stepId,
});

export const saveReportEdit = () => (dispatch, getState) => {
  console.log("here goes edit save");

  const { availableTemplates, uiState } = getState().reducer;
  const {
    templateId,
    template,
    answers: rawAnswers,
    hiddenSteps,
    skippedSteps,
    reportId,
  } = uiState.reportView;
  let answers = { ...rawAnswers };

  let { steps } = template;
  let stepsOrder = [...template.stepsOrder];

  stepsOrder.forEach(stepId => {
    const step = steps[stepId];
    if (
      ["rating", "bool"].includes(step.type.value) &&
      answers[stepId] === undefined &&
      !hiddenSteps[stepId] &&
      !skippedSteps[stepId]
    ) {
      answers[stepId] = defaultAnswers[step.type.value];
    }
  });

  const missingRequiredAnswers = stepsOrder.filter(stepId => {
    if (
      steps[stepId].required &&
      (answers[stepId] === undefined || answers[stepId] === "") &&
      steps[stepId].type.value !== "sectionName" &&
      !hiddenSteps[stepId]
    )
      return true;
    return false;
  });
  const isMissingAnswers = !!missingRequiredAnswers.length;
  if (isMissingAnswers) {
    dispatch({
      type: AT.REPORT_VIEW_MISSING_ANSWERS,
      payload: missingRequiredAnswers,
    });
    return;
  }

  let author = "";
  if (template.info.requireAuthorName) {
    author = answers[stepsOrder[0]];
    stepsOrder = stepsOrder.slice(1, stepsOrder.length);
  }

  const reportData = {
    _id: reportId,
    template_id: templateId,
    info: { author },
    values: stepsOrder.map(stepId => {
      const answer = answers[stepId];
      if (hiddenSteps[stepId] || skippedSteps[stepId])
        return { skipped: true, value: "" };
      if (steps[stepId].type.value === "sectionName") return { skipped: false };
      return { value: answer, skipped: answer === undefined };
    }),
  };

  console.log("Report data", reportData);

  dispatch({ type: AT.REPORT_VIEW_START_SENDING });
  const result = Reports.saveReportEdit(reportData)
    .then(() => {
      dispatch({ type: AT.REPORT_VIEW_SENDING_SUCCESS });
      push("/fillReport");
      notification.success({
        message: "Raport został wysłany",
        placement: "bottomRight",
      });
    })
    .catch(err => {
      dispatch({ type: AT.REPORT_VIEW_SENDING_ERROR });
      notification.error({
        message: "Błąd podczas wysyłania raportu",
        description:
          "Poczekaj chwilę i spróbuj ponownie. Jeśli błąd będzie dalej występował skontaktuj się z administratorem serwisu",
        placement: "bottomRight",
      });
    });
};

export const submitReport = () => (dispatch, getState) => {
  const { availableTemplates, uiState } = getState().reducer;
  const {
    templateId,
    answers: rawAnswers,
    hiddenSteps,
    skippedSteps,
  } = uiState.reportView;
  let answers = { ...rawAnswers };
  const template = availableTemplates.data[templateId];
  let { steps } = template;
  let stepsOrder = [...template.stepsOrder];

  stepsOrder.forEach(stepId => {
    const step = steps[stepId];
    if (
      ["rating", "bool"].includes(step.type.value) &&
      answers[stepId] === undefined &&
      !hiddenSteps[stepId] &&
      !skippedSteps[stepId]
    ) {
      answers[stepId] = defaultAnswers[step.type.value];
    }
  });

  const missingRequiredAnswers = stepsOrder.filter(stepId => {
    if (
      steps[stepId].required &&
      (answers[stepId] === undefined || answers[stepId] === "") &&
      steps[stepId].type.value !== "sectionName" &&
      !hiddenSteps[stepId]
    )
      return true;
    return false;
  });
  const isMissingAnswers = !!missingRequiredAnswers.length;
  if (isMissingAnswers) {
    dispatch({
      type: AT.REPORT_VIEW_MISSING_ANSWERS,
      payload: missingRequiredAnswers,
    });
    return;
  }

  let author = "";
  if (template.info.requireAuthorName) {
    author = answers[stepsOrder[0]];
    stepsOrder = stepsOrder.slice(1, stepsOrder.length);
  }

  const reportData = {
    template_id: templateId,
    info: { author },
    values: stepsOrder.map(stepId => {
      const answer = answers[stepId];
      if (hiddenSteps[stepId] || skippedSteps[stepId])
        return { skipped: true, value: "" };
      if (steps[stepId].type.value === "sectionName") return { skipped: false };
      return { value: answer, skipped: answer === undefined };
    }),
  };

  dispatch({ type: AT.REPORT_VIEW_START_SENDING });
  const result = Reports.sendReport(reportData)
    .then(() => {
      dispatch({ type: AT.REPORT_VIEW_SENDING_SUCCESS });
      push("/fillReport");
      notification.success({
        message: "Raport został wysłany",
        placement: "bottomRight",
      });
    })
    .catch(err => {
      dispatch({ type: AT.REPORT_VIEW_SENDING_ERROR });
      notification.error({
        message: "Błąd podczas wysyłania raportu",
        description:
          "Poczekaj chwilę i spróbuj ponownie. Jeśli błąd będzie dalej występował skontaktuj się z administratorem serwisu",
        placement: "bottomRight",
      });
    });
};

export const changeAnswerValue = (stepId, value, hiddenSteps) => ({
  type: AT.CHANGE_ANSWER_VALUE,
  payload: { value, stepId, hiddenSteps },
});

export const onAnswerChange = (stepId, value) => (dispatch, getState) => {
  const { uiState } = getState().reducer;
  const {
    templateId,
    hiddenSteps: currentHiddenSteps,
    template,
  } = uiState.reportView;

  const splitId = stepId.split("-");
  let currentStepNumber = parseInt(splitId[splitId.length - 1]);
  if (template.info.requireAuthorName) currentStepNumber--;
  let stepsToCheckDisplayRules = template.stepsOrder.reduce(
    (sum, checkedStepId) => {
      const step = template.steps[checkedStepId];
      const { displayRules } = step;
      if (!displayRules) return sum;
      const displayRulesWithChangedStep = displayRules.filter(
        rule => rule.step === currentStepNumber.toString()
      );
      if (displayRulesWithChangedStep.length > 0) {
        return [...sum, checkedStepId];
      }
      return sum;
    },
    []
  );
  const checkResult = dispatch(
    checkDisplayRulesForSteps(
      templateId,
      stepsToCheckDisplayRules,
      stepId,
      value
    )
  );
  const newHiddenSteps = { ...currentHiddenSteps };
  stepsToCheckDisplayRules.forEach((stepId, i) => {
    newHiddenSteps[stepId] = !checkResult[i];
  });

  dispatch(changeAnswerValue(stepId, value, newHiddenSteps));
};

export const checkDisplayRulesForSteps = (
  templateId,
  stepIds,
  changedStepId,
  changedStepValue
) => (dispatch, getState) => {
  const { uiState } = getState().reducer;
  const { answers: rawAnswers, template } = uiState.reportView;
  const answers = { ...rawAnswers };
  answers[changedStepId] = changedStepValue;
  const shiftQuestionNumber = template.info.requireAuthorName ? 1 : 0;
  const { steps } = template;
  return checkStepRules(steps, answers, stepIds, shiftQuestionNumber);
};

export const checkStepRules = (
  steps,
  answers,
  stepIds,
  questionNumberShift
) => {
  return stepIds.map(stepId => {
    const step = steps[stepId];
    if (!step || !step.displayRules) return true;

    const { displayRules } = step;
    return !displayRules.some(rule => {
      let ruleRule = rule.rule;
      if (ruleRule === "=") {
        ruleRule = "==";
      }
      let ruleStepId = `i-${parseInt(rule.step) + questionNumberShift}`;
      let stepValue = answers[ruleStepId];
      let stepType = steps[ruleStepId].type;
      if (stepValue === undefined) stepValue = null;
      let ruleValue = rule.value;

      if (typeof stepValue === "string") {
        stepValue = `"${stepValue}"`;
      }
      if (stepType.value === "text") {
        ruleValue = `"${ruleValue}"`;
      }
      const checkString = `${stepValue} ${ruleRule} ${ruleValue}`;
      return !eval(checkString);
    });
  });
};

const formatTemplate = t => {
  const id = t._id;
  const stepsOrder = [];
  const steps = {};
  t.steps.forEach((s, i) => {
    const index = `i-${i + 1}`;
    stepsOrder.push(index);
    steps[index] = { ...s };
  });
  const template = { ...t, steps, stepsOrder };
  return { template, id };
};

export const startReportEdit = reportId => (dispatch, getState) => {
  Reports.getForEdit(reportId).then(result => {
    let { answers: rawAnswers, template, info, id } = result.data;
    if (template.info.requireAuthorName) {
      template.steps = [getAuthorQuestion(), ...template.steps];
      rawAnswers = [{ value: info.author }, ...rawAnswers];
    }
    const { steps } = template;
    let answers = {};
    let hiddenSteps = {};
    let skippedSteps = {};
    for (let i = 0; i < steps.length; i++) {
      const answer = rawAnswers[i];
      let stepId = `i-${i + 1}`;
      if (answer === undefined || answer.skipped) {
        skippedSteps[stepId] = true;
      } else {
        answers[stepId] = answer.value;
      }
    }
    const formattedTemplate = formatTemplate(template).template;
    const checkResult = checkStepRules(
      formattedTemplate.steps,
      answers,
      formattedTemplate.stepsOrder,
      template.info.requireAuthorName ? 0 : 0
    );
    formattedTemplate.stepsOrder.forEach((stepId, i) => {
      hiddenSteps[stepId] = !checkResult[i];
    });

    Object.keys(skippedSteps).forEach(stepId => {
      if (hiddenSteps[stepId]) skippedSteps[stepId] = false;
    });

    dispatch({
      type: AT.START_REPORT_EDIT,
      payload: {
        id,
        answers,
        hiddenSteps,
        skippedSteps,
        template: formattedTemplate,
        templateId: template._id,
      },
    });

    dispatch(push("/editReport"));
  });
};

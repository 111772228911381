import { connect } from "react-redux";
import Component from "./EditReportView.jsx";
import { saveReportEdit } from "../../actions/ReportView";

const mapStateToProps = state => ({
  templateId: state.reducer.uiState.reportView.templateId,
  isSending: state.reducer.uiState.reportView.sending,
  hiddenSteps: state.reducer.uiState.reportView.hiddenSteps,
  template: state.reducer.uiState.reportView.template,
});

const mapDispatchToProps = {
  saveReportEdit,
};

const Container = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Container;

import * as AT from "../../constants/actionTypes";
import { validateProp } from "../../actions/register";

const VIEW_NAME = "newPassword";

const initialState = {
  password: "",
  confirmPassword: "",
  errors: {},
  state: "input",
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AT.CHANGE_INPUT_VALUE: {
      if (action.payload.view === VIEW_NAME) {
        const { name, value } = action.payload;
        let error = state.errors[name];
        if (error) {
          error = validateProp(name, value, state);
        }
        return {
          ...state,
          [name]: value,
          errors: { ...state.errors, [name]: error },
        };
      }
      return state;
    }
    case AT.SET_VIEW_STATE: {
      const { viewName, data } = action.payload;
      if (viewName === VIEW_NAME) return { ...state, ...data };
      return state;
    }
    case AT.RESET_VIEW_STATE: {
      if (action.payload === VIEW_NAME) return { ...initialState };
      return state;
    }
    default:
      return state;
  }
};

export default reducer;

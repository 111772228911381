import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { processModifiers } from "../../../utils/misc";

import "./dataTable.scss";

const DataTable = props => {
  const { header, items, modifiers, RowComponent } = props;
  const disabled = props.disabled || modifiers.includes("disabled");
  const baseClassName = classNames("c-dataTable", props.className, {
    "c-button--disabled": disabled
  });
  const className = processModifiers(baseClassName, "c-dataTable", modifiers);
  return (
    <table className={className}>
      {header}
      <tbody>
        {items.map((item,i) => (
          <RowComponent key={item.id} id={item.id} index={i} />
        ))}
      </tbody>
    </table>
  );
};

DataTable.defaultProps = {
  type: "button",
  modifiers: []
};

DataTable.propTypes = {
  className: PropTypes.string,
  modifiers: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.arrayOf(PropTypes.string)
  ]),
  type: PropTypes.string,
  id: PropTypes.string,
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  children: PropTypes.node
};

export default DataTable;

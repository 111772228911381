import React, { Component } from "react";
import { Button, Table, ConfigProvider, Popconfirm, Empty, Tag } from "antd";
import {
  DeleteTwoTone,
  EditTwoTone,
  WarningTwoTone,
  CopyTwoTone,
} from "@ant-design/icons";
import { Label } from "../../common";

class TemplatesTable extends Component {
  constructor(props) {
    super(props);
  }

  async edit(template) {
    const { startTemplateEdit, history } = this.props;
    await startTemplateEdit(template._id);
    history.push("/templates/current");
  }
  async duplicate(template) {
    console.log("tt", template);
    const { startTemplateEdit, history } = this.props;
    await startTemplateEdit(template._id, true);
    history.push("/templates/current");
  }

  delete(id) {
    this.props.deleteTemplate(id);
  }

  render() {
    const { templates, deleteEmployee, employees } = this.props;
    const { order, visible, data, loading } = templates;

    const processedTemplates = order
      .filter(id => visible.includes(id))
      .map(id => {
        const template = data[id];
        return {
          ...template,
          assignedEmployees: template.assignedEmployees
            .map(id => employees.data[id])
            .filter(e => !!e),
        };
      });

    return (
      <ConfigProvider
        renderEmpty={() => {
          if (loading) return <div className="loadingEmptySpace" />;
          return <Empty description={<p>Brak szablonów do wyświetlenia</p>} />;
        }}
      >
        <Table
          bordered
          loading={loading}
          size={"small"}
          pagination={{
            pageSize: 20,
            hideOnSinglePage: true,
            pageSizeOptions: ["5", "20", "50", "100", "200"],
            showSizeChanger: true,
            locale: { items_per_page: "" },
            showTotal: (total, range) => {
              return `${range[0]} - ${range[1]} z ${total} szablonów`;
            },
          }}
          dataSource={processedTemplates}
          columns={[
            {
              title: "Nazwa",
              align: "center",
              sorter: (a, b) => a.info.name.localeCompare(b.info.name),
              render: template => template.info.name,
            },
            {
              title: "Ilość kroków",
              align: "center",
              sorter: (a, b) =>
                a.stepsOrder.length > b.stepsOrder.length ? 1 : -1,
              render: template => template.stepsOrder.length,
            },
            {
              title: "Przypisane lokalizacje",
              align: "center",
              render: template => (
                <div className="c-label__container">
                  {template.assignedEmployees.map(e => (
                    <Tag color={"blue"}>{`${e.last_name} - ${
                      e.first_name
                    }`}</Tag>
                  ))}
                </div>
              ),
              sorter: (a, b) =>
                a.assignedEmployees.length > b.assignedEmployees.length ? 1 : -1,
            },
            {
              title: "Opcje",
              align: "center",
              width: "350px",
              render: template => (
                <React.Fragment>
                  <Button
                    icon={<CopyTwoTone />}
                    type={"link"}
                    onClick={() => {
                      this.duplicate(template);
                    }}
                  >
                    Duplikuj
                  </Button>
                  <Button
                    icon={<EditTwoTone />}
                    type={"link"}
                    onClick={() => {
                      this.edit(template);
                    }}
                  >
                    Edytuj
                  </Button>
                  <Popconfirm
                    title={
                      <span>
                        Czy na pewno chcesz usunąć ten szablon?
                        <br /> Operacja jest nieodwracalna!
                      </span>
                    }
                    onConfirm={() => {
                      this.delete(template._id);
                    }}
                    onCancel={() => {}}
                    okText="Usuń"
                    cancelText="Anuluj"
                    icon={<WarningTwoTone />}
                  >
                    <Button icon={<DeleteTwoTone />} type={"link"}>
                      Usuń
                    </Button>
                  </Popconfirm>
                </React.Fragment>
              ),
            },
          ]}
        />
      </ConfigProvider>
    );
  }
}

export default TemplatesTable;

import { connect } from "react-redux";
import Component from "./Modal.jsx";
import { hideModal } from "../../../actions/uiState";
import Header from "./ModalHeader.jsx";
import Body from "./ModalBody.jsx";
import Footer from "./ModalFooter.jsx";
import Title from "./ModalTitle.jsx";

const mapStateToProps = (state, ownProps) => ({
  visible: state.reducer.uiState.modal.currentModal === ownProps.name,
});

const mapDispatchToProps = (dispatch, ownProps) => {
  return {
    onCancel: ownProps.onCancel
      ? ownProps.onCancel
      : () => dispatch(hideModal(ownProps.name)),
  };
};

const AttachEmployeeContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(Component);

// I just want to have everything in one import object
AttachEmployeeContainer.Header = Header;
AttachEmployeeContainer.Body = Body;
AttachEmployeeContainer.Footer = Footer;
AttachEmployeeContainer.Title = Title;

export default AttachEmployeeContainer;

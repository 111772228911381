import * as AT from "../constants/actionTypes";

const initialState = {
  data: {},
  order: [],
  visible: [],
  searchBox: "",
  sorting: { key: "created_at", decr: true },
  loading: true,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AT.GET_REPORTS_SUCCESS: {
      const data = {};
      const order = [];
      action.payload.forEach(r => {
        data[r._id] = r;
        order.push(r._id);
      });
      return { ...state, data, order };
    }
    case AT.DELETE_REPORTS_SUCCESS: {
      const newData = { ...state.data };
      delete newData[action.payload];
      return {
        ...state,
        data: newData,
        order: state.order.filter(id => id !== action.payload),
      };
    }
    case AT.CHANGE_SEARCH_VALUE: {
      const { value, view } = action.payload;
      if (view === "reports") {
        return { ...state, searchBox: value };
      }
      return state;
    }
    case AT.UPDATE_VISIBLE_ELEMENTS: {
      if (action.payload.view === "reports") {
        return { ...state, visible: action.payload.visible };
      }
      return state;
    }
    case AT.UPDATE_ELEMENTS_ORDER: {
      if (action.payload.view === "reports") {
        return {
          ...state,
          order: action.payload.order,
          sorting: action.payload.sorting,
        };
      }
      return state;
    }
    case AT.CHANGE_VIEW_LOADING_STATUS: {
      if (action.payload.view === "reports") {
        return { ...state, loading: action.payload.isLoading };
      }
      return state;
    }
    default:
      return state;
  }
};

export default reducer;

import React from "react";

import "./topBar.scss";

const TopBar = props => {
  const { itemsLeft, itemsRight } = props;
  return (
    <div className="c-topBar">
      <div className="c-topBar__item">{itemsLeft}</div>
      <div className="c-topBar__item">{itemsRight}</div>
    </div>
  );
};

export default TopBar;

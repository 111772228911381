import { connect } from "react-redux";
import Component from "./UnauthApp.jsx";

const mapStateToProps = state => ({});

const mapDispatchToProps = {};

const Container = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Container;

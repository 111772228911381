import { combineReducers } from "redux";

import login from "./login.js";
import register from "./register.js";
import currentTemplate from "./currentTemplate";
import currentEmployee from "./currentEmployee";
import modal from "./modal";
import stepRulesModal from "./stepRulesModal";
import importTemplate from "./importTemplate";
import stepScoreModal from "./stepScoreModal";
import newReport from "./newReport";
import resetPassword from "./resetPassword";
import newPassword from "./newPassword";
import reportView from "./reportView";

const uiState = combineReducers({
  reportView,
  currentTemplate,
  currentEmployee,
  login,
  modal,
  newPassword,
  newReport,
  register,
  resetPassword,
  stepRulesModal,
  stepScoreModal,
  importTemplate,
});

export default uiState;

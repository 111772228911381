import { connect } from "react-redux";
import Component from "./HeaderItem.jsx";
import {updateElementsOrder} from "../../../actions/uiState";


const mapStateToProps = (state, ownProps) => ({
    uiState: state.reducer[ownProps.viewName].sorting,
});

const mapDispatchToProps = (dispatch, ownProps) => {
    return {
        onClick: (value) => {dispatch(updateElementsOrder(ownProps.viewName, value))}
    };
};

const AttachEmployeeContainer = connect(
    mapStateToProps,
    mapDispatchToProps
)(Component);

export default AttachEmployeeContainer;

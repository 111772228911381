import React, { Component } from "react";
import { Button, TextInput, Modal } from "../../common/";
import Select from "react-select";
import "./stepScoreModa.scss";
import Icon from "@mdi/react";
import { mdiDeleteForever } from "@mdi/js";

class StepScoreRule extends Component {
  constructor(props) {
    super(props);

    this.changeRuleValue = this.changeRuleValue.bind(this);
    this.changeRuleType = this.changeRuleType.bind(this);
    this.changeActionType = this.changeActionType.bind(this);
    this.changeActionValue = this.changeActionValue.bind(this);
    this.changeActionValueFromEvent = this.changeActionValueFromEvent.bind(
      this
    );
    this.delete = this.delete.bind(this);
  }
  changeRuleValue(option) {
    this.props.changeScoreRuleValue(
      this.props.index,
      "ruleValue",
      option.value
    );
  }
  changeActionValueFromEvent(e) {
    this.props.changeScoreRuleValue(
      this.props.index,
      "ruleValue",
      e.target.value
    );
  }

  changeRuleType(option) {
    this.props.changeScoreRuleValue(this.props.index, "ruleType", option.value);
  }

  changeActionType(option) {
    this.props.changeScoreRuleValue(
      this.props.index,
      "actionType",
      option.value
    );
  }
  changeActionValue(e) {
    this.props.changeScoreRuleValue(
      this.props.index,
      "actionValue",
      e.target.value
    );
  }

  delete() {
    this.props.deleteScoreRule(this.props.index);
  }

  render() {
    const { step } = this.props;
    const scoreTexts = {
      add: "do oceny raportu",
      subtract: "od oceny raportu",
      multiply: "ocenę raportu",
      divide: "ocenę raportu",
    };

    const numberRules = [
      { value: "equals", label: "jest równa", inputType: "numberInput" },
      {
        value: "not_equals",
        label: "nie jest równa",
        inputType: "numberInput",
      },
      {
        value: "smaller",
        label: "jest mniejsza niż",
        inputType: "numberInput",
      },
      { value: "greater", label: "jest większa niż", inputType: "numberInput" },
      {
        value: "smaller_equal",
        label: "jest mniejsza lub równa",
        inputType: "numberInput",
      },
      {
        value: "greater_equal",
        label: "jest większa lub równa",
        inputType: "numberInput",
      },
    ];

    const { ruleType, ruleValue, actionType, actionValue } = this.props.rule;

    // const ruleType = "equals";
    // const ruleValue = true;
    // const actionType = "add";
    // const actionValue = 5;
    let scoreText = scoreTexts[actionType];
    const isBool = step.type.value === "bool";
    return (
      <div className="stepScoreRule">
        <span className="stepScoreRule__label">Jeśli odpowiedź</span>
        {isBool ? (
          <span>
            {` `}
            <span className="stepScoreRule__label">jest równa</span>
          </span>
        ) : (
          <Select
            value={ruleType}
            searchable={false}
            clearable={false}
            options={numberRules}
            onChange={this.changeRuleType}
            className={"Select--width2"}
          />
        )}
        {isBool ? (
          <Select
            value={ruleValue}
            searchable={false}
            clearable={false}
            options={[
              { value: true, label: "Tak" },
              { value: false, label: "Nie" },
            ]}
            onChange={this.changeRuleValue}
          />
        ) : (
          <input
            type="text"
            className="stepScoreRule__valueInput"
            value={ruleValue}
            onChange={this.changeActionValueFromEvent}
          />
        )}
        <br />
        <span className="stepScoreRule__label">wtedy</span>
        <Select
          searchable={false}
          clearable={false}
          className="Select--width2"
          value={actionType}
          options={[
            { value: "add", label: " + dodaj" },
            { value: "subtract", label: " - odejmij" },
            { value: "multiply", label: " * pomnóż razy" },
            { value: "divide", label: " / podziel przez" },
          ]}
          onChange={this.changeActionType}
        />
        <input
          type="text"
          className="stepScoreRule__valueInput"
          value={actionValue}
          onChange={this.changeActionValue}
        />
        <br />
        <span className="stepScoreRule__label">{scoreText}.</span>
        <br />
        <Icon
          className="stepScoreRule__deleteIcon"
          path={mdiDeleteForever}
          size="24px"
          onClick={this.delete}
        />
      </div>
    );
  }
}

class StepScoreModal extends Component {
  constructor(props) {
    super(props);
    this.cancel = this.cancel.bind(this);
    this.save = this.save.bind(this);
    this.resetView = this.resetView.bind(this);
  }

  resetView() {
    this.props.resetView("stepScore");
  }
  save() {
    this.props.saveScoreRules();
    this.props.hideModal();
  }
  cancel() {
    this.props.hideModal();
  }

  render() {
    const { rules, stepId, loading } = this.props.uiState;

    const step = this.props.stepsState.steps[stepId];

    return (
      <Modal
        name="STEP_SCORE"
        className="c-stepRulesModal"
        title={"Ocena kroku"}
        cancelText="Anuluj"
        okText={"Zapisz"}
        onCancel={this.cancel}
        onOk={this.save}
        confirmLoading={loading}
        cancelButtonProps={{ disabled: loading }}
        afterClose={this.resetView}
      >
        <div className="c-stepRulesModal__contentWrapper">
          <div className="c-flex c-flex--vertical">
            {rules.map((rule, i) => (
              <StepScoreRule
                rule={rule}
                key={i}
                index={i}
                changeScoreRuleValue={this.props.changeScoreRuleValue}
                deleteScoreRule={this.props.deleteScoreRule}
                step={step}
              />
            ))}
            <div className="c-stepRulesModal__newRuleButtonContainer">
              <span
                className="c-stepRulesModal__newRuleButton"
                onClick={this.props.initNewScoreRule}
              >
                +
              </span>
            </div>
          </div>
        </div>
      </Modal>
    );
  }
}

export default StepScoreModal;

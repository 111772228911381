import * as AT from "../../constants/actionTypes";

const initialState = {
  email: "",
  password: "",
  rememberMe: true,
  error: null,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AT.CHANGE_INPUT_VALUE: {
      if (action.payload.view === "login") {
        const { name, value } = action.payload;
        return { ...state, [name]: value, error: null };
      }
      return state;
    }
    case AT.AUTH_USER_ERROR:
      return { ...state, error: action.payload };
    default:
      return state;
  }
};

export default reducer;

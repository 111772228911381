import * as AT from "../../constants/actionTypes";
import fieldTypes from "../../constants/templateFieldTypes";

const baseItem = {
  name: "",
  comment: "",
  type: fieldTypes[0],
  required: true,
  critical: false,
  points: 1,
  displayRules: [],
};

const initialState = {
  info: {
    name: "",
    description: "",
    requireAuthorName: true,
    rateReport: false,
    showPoints: false,
    scoreNames:{type:'percents',names:[]}
  },
  steps: {},
  stepsOrder: [],
};

const changeValueWithPath = (state, value, path, index = 0) => {
  if (path.length > index + 1)
    return {
      ...state,
      [path[index]]: changeValueWithPath(
        state[path[index]],
        value,
        path,
        index + 1
      ),
    };
  return { ...state, [path[index]]: value };
};

const reducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case AT.CHANGE_INPUT_VALUE: {
      if (action.payload.view === "currentTemplate") {
        const { name, value, path } = action.payload;
        if (path) {
          path.push(name);
          return changeValueWithPath(state, value, path);
        }
        return { ...state, [name]: value };
      }
      return state;
    }
    case AT.SAVE_RULES_FOR_STEP: {
      const { stepId, rules } = action.payload;
      return {
        ...state,
        steps: {
          ...state.steps,
          [stepId]: { ...state.steps[stepId], displayRules: rules },
        },
      };
    }
    case AT.SAVE_SCORE_RULES_FOR_STEP: {
      const { stepId, rules } = action.payload;
      return {
        ...state,
        steps: {
          ...state.steps,
          [stepId]: { ...state.steps[stepId], scoreRules: rules },
        },
      };
    }
    case AT.ADD_NEXT_TEMPLATE_ITEM: {
      const index = `i-${state.stepsOrder.length + 1}`;
      return {
        ...state,
        steps: { ...state.steps, [index]: { ...baseItem } },
        stepsOrder: [...state.stepsOrder, index],
      };
    }
    case AT.DELETE_TEMPLATE_ITEM: {
      let newSteps = {  };
      const stepToRemoveId = action.payload;
      const stepToRemoveNumber = state.stepsOrder.findIndex(id=>id===stepToRemoveId)+1;
      Object.keys(state.steps).forEach(stepId=>{
        if(stepId === stepToRemoveId) return;
        const step = state.steps[stepId];
        const {displayRules} = step;
        let didRulesChange = false;
        const newRules = displayRules.map(rule=>{
          const ruleStepNumber = parseInt(rule.step);
          if(ruleStepNumber<stepToRemoveNumber) return rule;
          didRulesChange = true;
          if(ruleStepNumber===stepToRemoveNumber) return null;
          return {...rule, step: (ruleStepNumber-1).toString()};
        }).filter(rule=>!!rule);
        if(didRulesChange) {
          newSteps[stepId] = {...step, displayRules: newRules};
        }else{
          newSteps[stepId] = step;
        }
      });

      return {
        ...state,
        steps: newSteps,
        stepsOrder: state.stepsOrder.filter(i => i !== action.payload),
      };
    }
    case AT.SAVE_CURRENT_TEMPLATE: {
      return { ...initialState };
    }
    case AT.RESET_VIEW_STATE: {
      if (action.payload === "currentTemplate") return { ...initialState };
      return state;
    }
    case AT.START_TEMPLATE_EDIT: {
      return ({...action.payload, info: {...initialState.info, ...(action.payload.info||{})}});
    }
    case AT.MOVE_TEMPLATE_STEP: {
      const { place, stepToMoveId, anchorStepId } = action.payload;
      const originalIndex = state.stepsOrder.findIndex(id=>id === stepToMoveId);
      let targetIndex =  state.stepsOrder.findIndex(id => id === anchorStepId);
      const direction = originalIndex<targetIndex?-1:1;
      if( place === 'before' && direction===-1) targetIndex--;
      if( place === 'after' && direction===1) targetIndex++;
      let lowerIndex, higherIndex;
      if(originalIndex<targetIndex){
        lowerIndex = originalIndex;
        higherIndex = targetIndex;
      }else{
        lowerIndex = targetIndex;
        higherIndex = originalIndex;
      }
      const newSteps = {...state.steps}
      Object.keys(state.steps).forEach(stepId=>{
        const step = state.steps[stepId];
        const stepIndex = state.stepsOrder.findIndex(id=> id === stepId);
        let didRulesChange = false;
        const {displayRules} = step;
        const newRules = displayRules.map(rule=>{
          const stepNo = parseInt(rule.step) - 1;
          if(stepNo === originalIndex){
            didRulesChange = true;
            if((stepIndex+direction) < targetIndex) return {...rule, step:'-1'};
            return {...rule, step: (targetIndex+1).toString()}
          }else if(stepNo >= lowerIndex && stepNo<= higherIndex){
            didRulesChange = true;
            if(stepId === stepToMoveId && parseInt(rule.step)>targetIndex){
             return {...rule, step:'-1'};
            }
            return {...rule, step: (parseInt(rule.step)+direction).toString()}
          }
          return rule;
        }).filter(rule=>rule.step>=0);
        if(didRulesChange) newSteps[stepId] = {...step, displayRules: newRules};

      })


      let newOrder = state.stepsOrder.filter(id => id !== stepToMoveId);
      let anchorIndex = newOrder.findIndex(id => id === anchorStepId);

      if (place === "after") anchorIndex++;
      newOrder = [
        ...newOrder.slice(0, anchorIndex),
        stepToMoveId,
        ...newOrder.slice(anchorIndex),
      ];
      return { ...state, stepsOrder: newOrder, steps: newSteps };
    }
    default:
      return state;
  }
};

export default reducer;

import { v4 } from "uuid";
import * as AT from "../constants/actionTypes";

const initialState = {
  data: {},
  order: [],
  visible: [],
  searchBox: "",
  loading: true,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case AT.GET_EMPLOYEES_SUCCESS: {
      const data = {};
      const order = [];
      action.payload.forEach(e => {
        const { _id } = e;
        data[_id] = e;
        order.push(_id);
      });
      return { ...state, data, order };
    }
    case AT.ADD_EMPLOYEE_SUCCESS: {
      const { _id } = action.payload;
      return {
        ...state,
        data: { ...state.data, [_id]: action.payload },
        order: [...state.order, _id],
      };
    }
    case AT.UPDATE_EMPLOYEE_SUCCESS: {
      const { _id } = action.payload;
      return {
        ...state,
        data: { ...state.data, [_id]: action.payload },
      };
    }
    case AT.DELETE_EMPLOYEE_SUCCESS: {
      const newData = { ...state.data };
      delete newData[action.payload];
      return {
        ...state,
        data: newData,
        order: state.order.filter(id => id !== action.payload),
      };
    }
    case AT.CHANGE_SEARCH_VALUE: {
      const { value, view } = action.payload;
      if (view === "employees") {
        return { ...state, searchBox: value };
      }
      return state;
    }
    case AT.UPDATE_VISIBLE_ELEMENTS: {
      if (action.payload.view === "employees") {
        return { ...state, visible: action.payload.visible };
      }
      return state;
    }
    case AT.CHANGE_VIEW_LOADING_STATUS: {
      if (action.payload.view === "employees") {
        return { ...state, loading: action.payload.isLoading };
      }
      return state;
    }

    default:
      return state;
  }
};

export default reducer;

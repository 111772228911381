import React, { useMemo } from "react";
import Question from "./ReportViewQuestion/ReportViewQuestion.redux";
import "./ReportView.scss";
import { Button, Col, Divider, PageHeader, Row } from "antd";
import {
  FileAddOutlined,
  RollbackOutlined,
  SaveOutlined,
} from "@ant-design/icons";

const ReportView = ({
  template,
  templateId,
  onSubmit,
  isSending,
  history,
  hiddenSteps,
  returnPath,
  QuestionComponent,
}) => {
  if (!templateId) history.push(returnPath);
  if (!template) return null;
  const { info, stepsOrder } = template;
  const { name: TemplateName } = info;
  const visibleStepIds = useMemo(
    () => stepsOrder.filter(stepId => !hiddenSteps[stepId]),
    [stepsOrder, hiddenSteps]
  );
  return (
    <>
      <Row>
        <Col span={24}>
          <PageHeader
            title={
              <span>
                <FileAddOutlined style={{ marginRight: "10px" }} />
                <span>{TemplateName}</span>
              </span>
            }
            extra={[
              <Button
                type="primary"
                icon={<RollbackOutlined />}
                onClick={() => {
                  history.push(returnPath);
                }}
              >
                Anuluj
              </Button>,
              <Button
                type="primary"
                icon={<SaveOutlined />}
                onClick={onSubmit}
                loading={isSending}
              >
                Zapisz
              </Button>,
            ]}
          />
        </Col>
      </Row>
      <div className="c-reportView__container">
        <div className="c-reportView__questionsContainer">
          {visibleStepIds.map((id, index) => (
            <Question
              key={id}
              stepId={id}
              templateId={templateId}
              index={index + 1}
            />
          ))}
        </div>
      </div>
    </>
  );
};

export default ReportView;

import { connect } from "react-redux";
import Component from "./PasswordResetView.jsx";
import { changeInputValue } from "../../actions/uiState";
import { resetPassword } from "../../actions/passwordReset";

const mapStateToProps = state => ({
  uiState: state.reducer.uiState.resetPassword,
});

const mapDispatchToProps = {
  changeInputValue,
  resetPassword,
};

const Container = connect(mapStateToProps, mapDispatchToProps)(Component);

export default Container;

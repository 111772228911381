import React, { Component } from "react";
import mousetrap from "mousetrap";
import { Button, Checkbox } from "antd";
import { Link } from "react-router-dom";
import { TextInput } from "../common/";
import "./loginView.scss";

class RegisterView extends Component {
  constructor(props) {
    super(props);
    this.handleInputChange = this.handleInputChange.bind(this);
    this.handleCheckbox = this.handleCheckbox.bind(this);
    this.submit = this.submit.bind(this);
  }

  componentDidMount() {
    mousetrap.bind("enter", this.submit);
  }

  componentWillUnmount() {
    mousetrap.bind("enter", this.submit);
  }

  handleInputChange(event) {
    this.props.changeInputValue("register", event);
  }
  handleCheckbox(event) {
    const { id, checked } = event.target;
    this.props.changeInputValue("register", {
      target: { type: "checkbox", name: id, checked },
    });
  }
  submit() {
    this.props.registerNewAccount();
  }
  render() {
    const { uiState } = this.props;
    const {
      firstName,
      lastName,
      companyName,
      phone,
      email,
      password,
      confirmPassword,
      accessCode,
      acceptTerms,
      errors,
      loading,
    } = uiState;

    return (
      <div className="c-register">
        <div className="c-register__container">
          <div className="c-register__title">Rejestracja</div>
          <TextInput
            id="firstName"
            value={firstName}
            onChange={this.handleInputChange}
            type="text"
            label="Imię"
            inputClassName="mousetrap"
            error={errors["firstName"]}
          />
          <TextInput
            id="lastName"
            value={lastName}
            onChange={this.handleInputChange}
            type="email"
            label="Nazwisko"
            inputClassName="mousetrap"
            error={errors["lastName"]}
          />
          <TextInput
            id="companyName"
            value={companyName}
            onChange={this.handleInputChange}
            type="email"
            label="Nazwa firmy"
            inputClassName="mousetrap"
            helpPopupContent={
              <span>
                Nazwa zostanie wyświetlona w panelu managera, a także w
                tworzonych raportach
              </span>
            }
            error={errors["companyName"]}
          />
          <TextInput
            id="phone"
            value={phone}
            onChange={this.handleInputChange}
            type="phone"
            label="Telefon"
            inputClassName="mousetrap"
            addonBefore={"+48"}
            helpPopupContent={
              <span>
                Potrzebujemy Twojego numeru telefonu, aby skontaktować się w
                razie jakichś problemów
              </span>
            }
            error={errors["phone"]}
          />
          <TextInput
            id="email"
            value={email}
            onChange={this.handleInputChange}
            type="email"
            label="E-mail"
            inputClassName="mousetrap"
            error={errors["email"]}
          />
          <TextInput
            id="password"
            value={password}
            onChange={this.handleInputChange}
            type="password"
            label="Hasło"
            inputClassName="mousetrap"
            helpPopupContent={
              <span>
                Min 8 znaków i co najmniej 1 cyfra lub znak specjalny. Im
                dłuższe i bardziej skomplikowane hasło, tym bezpieczniejsze jest
                Twoje konto.
              </span>
            }
            error={errors["password"]}
          />
          <TextInput
            id="confirmPassword"
            value={confirmPassword}
            onChange={this.handleInputChange}
            type="password"
            label="Potwierdź hasło"
            inputClassName="mousetrap"
            error={errors["confirmPassword"]}
          />

          <TextInput
            id="accessCode"
            value={accessCode}
            onChange={this.handleInputChange}
            type="text"
            label="Kod dostępu"
            inputClassName="mousetrap"
            helpPopupContent={
              <span>
                Obecnie trwają ostatnie testy aplikacji. Nowi użytkownicy są
                indywidualnie zapraszani do platformy. Aby uzyskać kod dostępu{" "}
                <a>skontaktuj się z nami</a>.
              </span>
            }
            error={errors["accessCode"]}
          />
          <Checkbox
            id={"acceptTerms"}
            checked={acceptTerms}
            onChange={this.handleCheckbox}
          >
            Zapoznałem/am się z{" "}
            <a href="./regulamin_reportio.pdf">regulaminem Reportio</a> oraz{" "}
            <a href="./polityka_prywatnosci_reportio.pdf">
              polityką prywatności
            </a>{" "}
            i akceptuje ich postanowienia.
          </Checkbox>

          <Button
            disabled={!acceptTerms}
            loading={loading}
            type="primary"
            modifiers={["purple"]}
            onClick={this.submit}
            className={"loginButton"}
          >
            {loading ? "Tworzenie konta" : "Stwórz konto"}
          </Button>
        </div>
      </div>
    );
  }
}

export default RegisterView;

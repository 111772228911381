import * as AT from "../../constants/actionTypes";
import { validateProp } from "../../actions/employees";

const initialState = {
  loading: false,
  firstName: "",
  lastName: "",
  email: "",
  password: "",
  notificationEmail: "",
  id: null,
  errors: {},
};

const reducer = (state = { ...initialState }, action) => {
  switch (action.type) {
    case AT.CHANGE_INPUT_VALUE: {
      if (action.payload.view === "currentEmployee") {
        const { name, value } = action.payload;
        let error = state.errors[name];
        if (error) {
          error = validateProp(name, value, state);
        }
        return {
          ...state,
          [name]: value,
          errors: { ...state.errors, [name]: error },
        };
      }
      return state;
    }
    case AT.RESET_VIEW_STATE: {
      if (action.payload === "currentEmployee") return { ...initialState };
      return state;
    }
    case AT.SET_VIEW_STATE: {
      const { viewName, data } = action.payload;
      if (viewName === "currentEmployee") return { ...state, ...data };
      return state;
    }
    default:
      return state;
  }
};

export default reducer;
